export const VID = [
    {id: 1, img: "https://youtu.be/EePS6MBmsvI?si=kza5fVS4U2V-aypK", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 2,img: "https://youtu.be/9TUE_OjufpA?si=pdWBhuHLC8sj_InS", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 3,img: "https://youtu.be/UH1j6PzBhjU?si=N9avvcC-0j4LVyJr", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 4,img: "https://youtu.be/eGkhMZdBPBA?si=0UDsHtm7AaF9-eHS", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 5,img: "https://youtu.be/UiH2P-FR2mI?si=oydvMWzkYTxZPUPx", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 6,img: "https://youtu.be/dIdgYCfOQkI?si=N3TIBfjVOMbPr__C", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 7,img: "https://youtu.be/zEoUKcPucK8?si=TJU7z1x0ZOJizDvd", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 8,img: "https://youtu.be/Tt9c_nTtVcU?si=g4s7i7SlzXhH5pgg", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 9,img: "https://youtu.be/ZzJu2n9wvx0?si=INHCkweRCquRo5kz", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
    {id: 10,img: "https://youtu.be/3QQhfn5_v4E?si=pO0_RzJdC2nlm-QE", title: "TITLE", about: "about", thumb: "2CCN_wVgyUE"},
  ]
