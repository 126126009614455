import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import "react-image-lightbox/style.css";
import { VID } from "./VID";
import { useState } from "react";
import WhiteModal from "./Modal";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { ClearOutlined } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    para_style: {
        textAlign: "justify"
    },
    fontHeading: {
        marginTop: "-40px",
        fontSize: "32px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-50px",
            textAlign: "center",
            fontSize: "20px"
        }
    },
    musicImage: {
        width: "362px",
        borderRadius: "10px",
        marginTop: "-50px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginTop: "0px",
            marginLeft: "0px"
        }
    },
    orangeHeading: {
        color: "#F46A00",
        fontSize: "24px", // Adjust the size as needed
    },
    imageCover: {
        objectFit: 'cover'
    },

    video : {
        width: "250px",
        height: "80%",
        borderRadius: "5px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
      },

      NameContainerVideo: {
       color: "#F46A00",
       marginTop: "5px",
       marginLeft: "10px"
      },

      VideoDescription: {
        color: "#FF7C17B2",
        fontSize: "10px"
      },
     
      VideoName: {
        marginTop: "0px",
        fontSize: "12px",
        marginBottom: "-2px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "8px",
        },
      },

      VideoContainer: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "20px"
      },

      frameContainer: {
        marginRight: "30px",
        marginBottom: "50px",
        position: "relative", 
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
      },





      videoContainer_a : {
        width: "100%",
        height: "90%",
        display: "flex",
        position: "relative",

      },
  
      video_a : {
        width: "70%",
        height: "100%",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
      },

      NameContainerVideo_a : {
        background: "#ff83002b",
        width: "30%",
        height: "100%",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        paddingLeft: "30px",
        paddingRight: "30px",
        marginLeft: "10px",
        marginBottom: "10px",
        color: "#F46A00"
      },
     
      VideoName_a : {
        marginTop: "20px",
        fontSize: "36px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            fontSize: "20px",
        },
      },

      closeIcon_a : {
        position: "absolute",
        right: "0px",
        margin: "10px",
        cursor: "pointer"
      },

      ////////////////////////////////

      video_b : {
        width: "250px",
        height: "80%",
        borderRadius: "5px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
      },

      NameContainerVideo_b: {
       color: "#F46A00",
       marginTop: "5px",
       marginLeft: "10px"
      },

      VideoDescription_b: {
        color: "#FF7C17B2",
        fontSize: "10px"
      },

      
     
      VideoName_b: {
        marginTop: "0px",
        fontSize: "12px",
        marginTop: "-10px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "8px",
        },
      },

      VideoContainer_b: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "20px"
      },

      frameContainer_b: {
        marginRight: "30px",   
        
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            marginRight: "0px",
        },
      },

      listContainer: {
        marginTop: "20px",
        overflowY: "scroll",
        height: "90%"
      },

      VideoMainContainer: {
        display: "flex",
        flexDirection: "column",
        marginLeft: "10px",
        marginTop: "5px"
      },

      videoMainHeading: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#F46A00",
        textDecoration: "underlined"
      },

      VideoMainAbout: {
        fontSize: "12px",
        color: "#FF7C17B2",
        marginLeft: "20px"
      },
      play: {
        color: "red !important",
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        zIndex: "100"
      }
}));


const VideoMainGallery = () => {
    const [modal, setModal] = useState(false)
    const [url, setUrl] = useState([])

    const classes = useStyles();

    const handleVideo = (data) => {
        setModal(true)
        setUrl(data)
      }

      const handlClose = () => {
        setModal(false)
      }

      console.log(modal)

    return (
        <section className="section section-intro mt-2" id="intro4">
            <div className="container">
                <Grid container
                    spacing={3}
                    alignItems="center">
                    <Grid item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}>
                        <div>
                            <p className="text-primary m-0 mb-4 text-20">
                                <h1 className={
                                        classes.fontHeading
                                    }
                                    style={
                                        {position: "absolute"}
                                }>
                                    Launch Ceremony
                                </h1>
                            </p>      
                        </div>
                       
                    </Grid>
                </Grid>
                <div  className={classes.VideoContainer} >
                            
                        </div>
            </div>
            <WhiteModal open={modal} onClose={handlClose}>
              <div className={classes.videoContainer_a}>
                <ClearOutlined className={classes.closeIcon_a} onClick={handlClose} />
                <iframe className={classes.video_a}  src={`${url.img}autoplay=1`} title="YouTube video player" frameborder="0" ></iframe>    
                 <div className={classes.NameContainerVideo_a} >
                    <div className={classes.listContainer} >
                 {
                                VID.filter(img => img.id !== url.id).map((img) => (
                                    <div className={classes.frameContainer_b} onClick={() => {
                                        handleVideo(img)
                                      }} >
                                        <PlayCircleOutlineIcon className={classes.play} />
                                        <img className={classes.video_b} src={`https://img.youtube.com/vi/${img.thumb}/maxresdefault.jpg`} alt="video" />
                                        <div className={classes.NameContainerVideo_b} >
                                            <h2 className={classes.VideoName_b} >{img.title}</h2>
                                        </div>
                                    </div>
                                ))
                            }
                            </div>
                </div>
              </div>
               <div className={classes.VideoMainContainer} >
                    <span className={classes.videoMainHeading} >{url.title}</span>
                    <span className={classes.VideoMainAbout} >{url.about}</span>
                </div>             
            </WhiteModal>                
        </section>
    );
};

export default VideoMainGallery;
