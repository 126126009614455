import React, { useRef, useEffect } from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import WhiteModal from "./Modal";
import { useState } from "react";
import { ClearOutlined } from "@material-ui/icons";
import { VID } from "./VID";
import YouTube from "react-youtube";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';


const useStyles = makeStyles((theme) => ({
    img: {
        width: "400px",
        height: "300px",
        objectFit: "cover",
        cursor: "pointer",
        borderRadius: "10px",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.4)",
        [theme.breakpoints.down("xs")]: {
          width: "170px",
          height: "200px",
      },
    },
    fontHeading: {
        marginTop: "-70px",
        fontSize: "36px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-50px",
            textAlign: "center",
            fontSize: "20px",
        },
    },
    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-100px",
        },
    },

    

    

    seeAllButton: {
        backgroundColor: "#fff",
        border: "1px solid #F46A00 " ,
        color: "#F46A00", 
        borderRadius: "10px",
        padding: "5px 170px",
        display: "block",
        margin: "auto",
        marginTop: "40px",
        [theme.breakpoints.down("xs")]: {
          padding: "5px 50px",
      },
      },
      galleryContainer: {
        marginLeft: "113px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0px",
      },
      "@media screen and (max-width: 950px) and (min-width: 501px)" :{
        marginLeft: "0px",
    }
      },


      videoContainer: {
          position: "relative",
          paddingBottom: "56.25%",
          height: "0",
          overflow: "hidden",

      },
    iframe: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%"

    },
  
      video : {
        width: "70%",
        height: "100%",
        borderTopLeftRadius: "15px",
        borderBottomLeftRadius: "15px",
      },

      NameContainerVideo: {
        background: "#ff83002b",
        width: "30%",
        height: "100%",
        borderTopRightRadius: "15px",
        borderBottomRightRadius: "15px",
        paddingLeft: "30px",
        paddingRight: "30px",
        marginLeft: "10px",
        marginBottom: "10px",
        color: "#F46A00"
      },
     
      VideoName: {
        marginTop: "20px",
        fontSize: "36px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            fontSize: "20px",
        },
      },

      closeIcon: {
        position: "absolute",
        right: "0px",
        margin: "10px",
        cursor: "pointer"
      },
      play: {
        color: "red !important",
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        right: "0",
        zIndex: "100",
        marginTop: "100px",
        fontSize: "100px",
        left: "-115px",
        [theme.breakpoints.down("xs")]: {
          marginTop: "75px",
          fontSize: "50px",
          left: "9px",
      },
      }
}))

function VideoGallery() {
    const [modal, setModal] = useState(false)
    const [url, setUrl] = useState("")
    const classes = useStyles();
    const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
        new window.Swiper(swiperRef.current, {
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            clickable: true,
          },
          effect: "coverflow",
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
          slidesPerView: 2  ,
          centeredSlides: true,
          style: { height: "700px" },
          autoplay: {
            delay: 1000,
            disableOnInteraction: false,
          },
          
        });
      }
  }, []);


    const VID = [
        {id: 1,vid: "EePS6MBmsvI",src: "https://youtu.be/EePS6MBmsvI?si=kza5fVS4U2V-aypK"},
        {id: 2,vid: "9TUE_OjufpA",src: "https://youtu.be/9TUE_OjufpA?si=pdWBhuHLC8sj_InS"},
        {id: 4,vid: "eGkhMZdBPBA",src: "https://youtu.be/eGkhMZdBPBA?si=0UDsHtm7AaF9-eHS"},
        {id: 5,vid: "UiH2P-FR2mI",src: "https://youtu.be/UiH2P-FR2mI?si=oydvMWzkYTxZPUPx"},
        {id: 6,vid: "dIdgYCfOQkI",src: "https://youtu.be/dIdgYCfOQkI?si=N3TIBfjVOMbPr__C"},
        {id: 7,vid: "zEoUKcPucK8",src: "https://youtu.be/zEoUKcPucK8?si=TJU7z1x0ZOJizDvd"},
        {id: 8,vid: "Tt9c_nTtVcU",src: "https://youtu.be/Tt9c_nTtVcU?si=g4s7i7SlzXhH5pgg"},
        {id: 9,vid: "ZzJu2n9wvx0",src: "https://youtu.be/ZzJu2n9wvx0?si=INHCkweRCquRo5kz"},
    
    ]
    const IMG = [
        {id: 1, img: "/assets/images/gallery/IMG-20231026-WA0002.jpg"},
        {id: 2,img: "/assets/images/gallery/IMG-20231026-WA0003.jpg"},
        {id: 3,img: "/assets/images/gallery/IMG-20231026-WA0004.jpg"},
        {id: 4,img: "/assets/images/gallery/IMG-20231026-WA0005.jpg"},
        {id: 5,img: "/assets/images/gallery/IMG-20231026-WA0006.jpg"},
        {id: 6,img: "/assets/images/gallery/IMG-20231026-WA0007.jpg"},
        {id: 7,img: "/assets/images/gallery/IMG-20231026-WA0008.jpg"},
    ]
  
  const handleVideo = (id) => {
    setModal(true)

    const isUrl = VID.filter(image => image.id === id)
    
    setUrl(isUrl[0])
  }

  const handlClose = () => {
    setModal(false)
  }

  console.log(url)
    const opts = {
        height: '100%',
        width: '1024',
    };
  return (
    <section className={`${classes.container_top} section section-intro`} id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className="text-primary m-0 mb-4 text-20">
                                <h1 className={classes.fontHeading} style={{position: "absolute"}}>Launch Ceremony</h1>
                            </p>
                            <div >
                                    <div className={`swiper-container mt-10  ${classes.galleryContainer} `} ref={swiperRef}>
                                        <div className="swiper-wrapper">
                                        {/* Sample slides */}
                                        {VID?.map((image, index) => (
                                            <a target="_blank"/* href={image.src}*/ style={{position: "relative"}} href={`https://www.youtube.com/watch?v=${image.vid}&list=PLyFDjHW4hCF4yOna09ZB-y_7FIhuBbgQ2`} className="swiper-slide" key={index}>
                                              <PlayCircleOutlineIcon className={classes.play} />
                                              <img  className={classes.img} src={`https://img.youtube.com/vi/${image.vid}/hqdefault.jpg`} alt={`Slide ${index + 1}`} />
                                            </a>
                                        ))}
                                        </div>
                                    </div>
                                    {/*
                                          <a href="/videogallery">
                                            <Button  variant="contained" className={classes.seeAllButton}>
                                              See All
                                          </Button>
                                        </a>*/}
                            </div>
                    </Grid>
                </Grid>               
            </div>
            <WhiteModal open={modal} onClose={handlClose}>
              <div className={classes.videoContainer}>
                <ClearOutlined className={classes.closeIcon} onClick={handlClose} />
                  <YouTube videoId={url.vid}  />
              </div>
                                    
            </WhiteModal>
        </section>
  );
}

export default VideoGallery;
