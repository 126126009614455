import React from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import {MuiThemeProvider} from "@material-ui/core/styles";
import Scrollbar from "react-perfect-scrollbar";
import {Theme} from "./theme";
import GlobalCss from "./styles/jss/GlobalCss";
import Cs from "./home/Cs";
import Terms from "./home/Terms";
import TermsWeb from "./home/TermsWeb";
import Policy from "./home/Policy";
import PolicyWeb from "./home/PolicyWeb";
import IntroAbout from "./home/IntroAbout";
import IntroGalley from "./home/IntroGallery";
import VideoMainGallery from "./home/sections/VideoMainGallery";
import IntroVideoGallery from "./home/IntroVideoGallery";
import FAQMAIN from "./home/FAQmain";
import IntroAboutus from "./home/IntroAboutus";
import IntroEvent from "./home/IntroEvent";
import CountDownComponents from "./home/sections/cs/CountDownComponents";


function App() {
    return (
        <MuiThemeProvider theme={Theme}>
            
            <GlobalCss>
            
                <Scrollbar
                    className="h-full-screen scrollable-content"
                    option={{suppressScrollX: true}}
                >
                    <Router basename="/">
                        <Switch>
                            <Route path="/gallery" component={IntroGalley}/>    
                            <Route path="/videogallery" component={IntroVideoGallery}/>  
                            <Route path="/event" component={IntroEvent}/>  
                            <Route path="/aboutus" component={IntroAboutus}/>
                            <Route path="/faq" component={FAQMAIN}/>    
                            <Route path="/about" component={IntroAbout}/>
                            {/* <Route path="/terms" component={Terms}/> */}
                            <Route path="/terms" component={TermsWeb}/>
                            {/* <Route path="/privacy-policy" component={Policy}/> */}
                            <Route path="/privacy-policy" component={PolicyWeb}/>
                            <Route path="/" component={Cs}/>
                            <Route component={Error}/>
                        </Switch>
                    </Router>
                </Scrollbar>
            </GlobalCss>
        </MuiThemeProvider>
    );
}

export default App;
