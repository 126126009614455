import React, { useRef, useEffect } from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Button, Grid, Link } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";


const useStyles = makeStyles((theme) => ({
    img: {
        width: "400px",
        height: "300px",
        objectFit: "cover",
        cursor: "pointer",
        borderRadius: "10px",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.4)",
        [theme.breakpoints.down("xs")]: {
          width: "170px",
          height: "200px",
      },
    },
    fontHeading: {
        marginTop: "-70px",
        fontSize: "36px",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-50px",
            textAlign: "center",
            fontSize: "20px",
        },
    },
    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-100px",
        },
    },
    seeAllButton: {
        backgroundColor: "#fff",
        border: "1px solid #F46A00 " ,
        color: "#F46A00", 
        borderRadius: "10px",
        padding: "5px 170px",
        display: "block",
        margin: "auto",
        marginTop: "40px",
        [theme.breakpoints.down("xs")]: {
          padding: "5px 50px",
      },
      },
      galleryContainer: {
        marginLeft: "113px",
        [theme.breakpoints.down("xs")]: {
          marginLeft: "0px",
      },
      "@media screen and (max-width: 950px) and (min-width: 501px)" :{
        marginLeft: "0px",
    }
      },
}))

function App() {

    
    const classes = useStyles();
    const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current) {
        new window.Swiper(swiperRef.current, {
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          pagination: {
            clickable: true,
          },
          effect: "coverflow",
          coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: false,
          },
          slidesPerView: 2  ,
          centeredSlides: true,
          style: { height: "700px" },
          autoplay: {
            delay: 1000,
            disableOnInteraction: false,
          },
          
        });
      }
  }, []);


  const IMG = [
    {img: "/assets/images/gallery/IMG-20231026-WA0002.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0003.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0004.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0005.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0006.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0007.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0008.jpg"},
  
    {img: "/assets/images/gallery/IMG-20231026-WA0010.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0011.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0012.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0013.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0014.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0015.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0016.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0017.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0018.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0019.jpg"},

    {img: "/assets/images/gallery/IMG-20231026-WA0020.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0021.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0022.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0023.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0024.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0025.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0026.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0027.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0028.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0029.jpg"},
    
    {img: "/assets/images/gallery/IMG-20231026-WA0030.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0031.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0032.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0033.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0034.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0035.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0036.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0038.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0039.jpg"},

    {img: "/assets/images/gallery/IMG-20231026-WA0040.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0041.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0042.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0043.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0044.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0045.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0046.jpg"},
    {img: "/assets/images/gallery/IMG-20231026-WA0047.jpg"},


  ]



 

  return (
    <section className={`${classes.container_top} section section-intro`} id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className="text-primary m-0 mb-4 text-20">
                                <h1 className={classes.fontHeading} style={{position: "absolute"}}>Glimpses of Chinmaya Birth Centenary Celebrations 2015.</h1>
                            </p>
                            <div >
                                    <div className={`swiper-container mt-10  ${classes.galleryContainer} `} ref={swiperRef}>
                                        <div className="swiper-wrapper">
                                        {/* Sample slides */}
                                        {IMG?.map((image, index) => (
                                            <div className="swiper-slide" key={index}>
                                            <img className={classes.img} src={image.img} alt={`Slide ${index + 1}`} />
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    {/* Add the "See All" button */}
                                          <a href="/gallery">
                                            <Button  variant="contained" className={classes.seeAllButton}>
                                              See All
                                          </Button>
                                        </a>
                            </div>
                    </Grid>
                </Grid>               
            </div>
        </section>
  );
}

export default App;
