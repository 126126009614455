import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { darken } from "@material-ui/core/styles";
import FacebookIcon from "../../common/icons/FacebookIcon";
import TwitterIcon from "../../common/icons/TwitterIcon";
import InstagramIcon from "../../common/icons/InstagramIcon";

const useStyles = makeStyles(({palette, ...theme}) => ({
    section: {
        background: "#e47833",
        color: palette.primary.contrastText,
    },
    termsTop: {
        marginTop: "18px"
    },
    iconWrapper: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        padding: 4,
        borderRadius: 4,
        marginRight: 12,
        border: `1px solid ${palette.primary.contrastText}`,
        cursor: "pointer",
    },
    link: {
        borderRadius: 4,
        "&:hover": {
            background: darken("#e47833", 0.2),
        },
    },
}));

const companyOption = ["About Us", "Services", "Team", "Pricing", "Project"];

const userfulLink = [
    "Terms of Services",
    "Privacy Policy",
    "Documentation",
    "Changelog",
    "Components",
];

const Footer2 = () => {
    const classes = useStyles();

    return (
        <section className={`section ${classes.section}`} id="contact4">
            <div className="container">
                <Grid container spacing={3}>
                    <Grid item lg={9} md={6} sm={6} xs={12}>
                        <div className="footer1__about">
                            <h4 className="text-24 font-normal m-0">About Us</h4>
                            <p className="my-6 max-w-500 text-inherit">
                            Chinmaya Seva Trust Kerala was founded on 29-12-1986 provides co-ordination and guidance to the Chinmaya Mission activities in Kerala under six zonal Chinmaya Mission Educational, Cultural & Charitable Trusts from Kannur to Trivandrum. Each of these Trusts are independent in terms of finance, assets and activities but under the overall guidance of the Chinmaya Seva Trust Kerala, which in turn receives the same from the Central Chinmaya Mission Trust, Mumbai which is the head quarters for co-ordinating all Mission activities all over India and abroad. 
                            </p>
                            <div className={classes.termsTop}>
                                <div>
                                For support, please go through our  <a style={{color: "rgb(83, 217, 255)"}}
                                                                          href="/faq">FAQ</a> section or write to <a style={{color: "rgb(83, 217, 255)"}}
                                          href="mailto:chinmayasankaram2024@gmail.com"> chinmayasankaram2024@gmail.com </a>
                                          or <a style={{color: "rgb(83, 217, 255)"}}
                                          href="tel:+919539333890"> +91-9539333890</a>
                                          
                                </div>
                            </div>
                            <div className={classes.termsTop}>
                                <div>Copyright &#169;  2023 Chinmaya. All Rights Reserved</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                        <div className="flex flex-wrap">
                            <a href="https://www.facebook.com/profile.php?id=61551502393158&mibextid=9R9pXO" target="_blank">
                                <div className={classes.iconWrapper}>
                                    <FacebookIcon className="text-13" fontSize="small"/>
                                </div>
                            </a>
                            <a href="https://www.instagram.com/chinmayasankaram2024/?utm_source=qr&igshid=OGIxMTE0OTdkZA%3D%3D" target="_blank">
                                <div className={classes.iconWrapper}>
                                    <InstagramIcon className="text-13" fontSize="small"/>
                                </div>
                            </a>
                            <a href="https://twitter.com/chinmaya_108?t=VrGJziuWHdlV2iPqvhR-qQ&s=09" target="_blank">
                                <div className={classes.iconWrapper}>
                                    <TwitterIcon className="text-13" fontSize="small"/>
                                </div>
                            </a>
                        </div>
                        <div className={classes.termsTop}>
                            <div><a href={"/terms"}>Terms of Use</a></div>
                            <div><a href={"/privacy-policy"}>Privacy Policy</a></div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </section>
    );
};

export default Footer2;
