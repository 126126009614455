import React from 'react'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({palette, ...theme}) => ({
    banner_img: {
        marginTop: "150px",
        width: "100%",
        boxShadow: "10px",
        marginBottom: "-80px"
    },


    pc_logo: {
      display: "none"
    },
    
    swamiContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "50px",
      marginRight: "50px"
    },

    swami: {
      width: "19%",
      marginTop: "-14%",
      height: "300px",
      borderRadius: "10px"

    },

    swami1: {
      
    },

    swami2: {
     
    },

    [theme.breakpoints.down("xs")]: {
        pc_logo: {
          display: "block",
          width: "50px",
          marginTop: "15px",
          marginLeft: "20px",
        },
        banner_img: {
          marginTop: "55px",
      },

      swami: {
        width: "22%",
        marginTop: "0%",
        height: "100px"
        
      },

      swamiContainer: {
        marginLeft: "10px",
        marginRight: "10px"
      },
      container_div: {
        marginBottom: "-60px"
      }
  },

}));


function Banner1() {

    const classes = useStyles()


  return (
    <div className={classes.container_div} >
        <a href="/" > <img src="/assets/images/cs/chinmaya_logo.png" alt="" className={classes.pc_logo} /></a>
    </div>
  )
}

export default Banner1