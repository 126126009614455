import React from "react";
import {makeStyles, lighten} from "@material-ui/core/styles";
import {Grid, useMediaQuery, Icon, Button, Fab, Tooltip} from "@material-ui/core";
import clsx from "clsx";

import styles from "../../../styles/style.css"
import { FormatQuote} from "@material-ui/icons";


const useStyles = makeStyles(({palette, ...theme}) => ({
    Icon: {
        fontSize: "15px"
    },
    wrapper: {
        position: "relative",
        "&::after": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            left: "calc(-100vw + 50% - 300px)",
            background: palette.primary.main,
            opacity: 0.1,
            borderTopRightRadius: 300,
            borderBottomRightRadius: 300,
            zIndex: -1,
        },
        "&::before": {
            content: '" "',
            display: "block",
            position: "absolute",
            height: "100%",
            width: "100vw",
            top: 0,
            right: "calc(-100vw + 50% - 300px)",
            background: palette.primary.main,
            opacity: 0.1,
            borderTopLeftRadius: 300,
            borderBottomLeftRadius: 300,
            zIndex: -1,
        },
    },
    om_wrapper: {
        width: "150px",
    },
}));

const Features2 = () => {
    const classes = useStyles();

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section" id="Features2">
            <div className="container">
                <div className={clsx("text-center mx-auto", classes.wrapper)}>
                    <img src="/assets/images/cs/oom.png" className={classes.om_wrapper} />
                    <p className="max-w-400 mx-auto mb-8">
                       <FormatQuote className={classes.Icon} /> <i> To give maximum happiness to maximum people for maximum time.</i> <FormatQuote className={classes.Icon} />
                    </p>

                </div>
            </div>
        </section>
    );
};

export default Features2;
