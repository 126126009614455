import React from 'react';
import Countdown from 'react-countdown';
import "./count_style.css"

const CountDownComponents = () => {

  const targetDate = new Date('May 8, 2024 00:00:00 GMT');

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span>Countdown Completed!</span>;
    } else {
    
      return (
        <div className='time_count' >
          <div className='main_continer' >
            <p className='sub_para1' >{days}</p>
            <p className='sub_para2' >Days</p>
          </div>
          <div className='main_continer' >
            <p className='sub_para1' >{hours}</p>
            <p className='sub_para2' >Hour</p>
          </div>
          <div className='main_continer' >
            <p className='sub_para1' >{minutes}</p>
            <p className='sub_para2' >Minutes</p>
          </div>
          <div className='main_continer' >
            <p className='sub_para1' >{seconds}</p>
            <p className='sub_para2' >Seconds</p>
          </div>
        </div>
       
      );
    }
  };

  return (
    <Countdown
      date={targetDate}
      renderer={renderer}
      onComplete={() => console.log('Countdown completed!')}
    />
  );
};

export default CountDownComponents;
