"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = void 0;
var indigo = {
    50: '#F46A00',
    100: '#F46A00',
    200: '#F46A00',
    300: '#F46A00',
    400: '#F46A00',
    500: '#F46A00',
    600: '#F46A00',
    700: '#F46A00',
    800: '#F46A00',
    900: '#F46A00',
    A100: '#F46A00',
    A200: '#F46A00',
    A400: '#F46A00',
    A700: '#F46A00'
};
var _default = indigo;
exports.default = _default;
