import React, {createContext, useEffect, useState} from "react";
import {scrollTo} from "../utils";
import TopBar4 from "./sections/cs/TopBar4";
import Intro4 from "./sections/cs/Intro4";
import Intro5 from "./sections/cs/Intro5";
import Features4 from "./sections/cs/Features4";
import Features2 from "./sections/cs/Features2";
import Footer2 from "./sections/cs/Footer2";
import Banner from "./sections/Banner";
import About from "./sections/About";
import Mission from "./sections/Mission";
import Gallery from "./sections/Gallery";
import VideoGallery from "./sections/VideoGallery";
import TenativeProgram from "./sections/cs/TenativeProgram";
import modalContext from "./modal-context";




const Cs = () => {
    useEffect(() => {
        scrollTo("root");
    });

    

    const [isTentative, setIsTenative] = useState(false)

    const handleOpenModal = () => {
        setIsTenative(true)
    }

    const handleCloseModal = () => {
        setIsTenative(false)
    }

    return (
        <>
       
        <modalContext.Provider value={{handleOpenModal, handleCloseModal}}  >
        <>
            <div className="landing">
               
                <TopBar4/>
                <Banner/>
                <Intro4/>
                <VideoGallery/>
                <Features2/>
                <About/>
                <Mission/>
                <Gallery/>
                <Intro5/>
                <Footer2/>
            </div>
            {
                isTentative && <TenativeProgram/>
            }
        </>
        </modalContext.Provider>
        </>
    );
};

export default Cs;
