import React, {useState, useEffect, useContext} from "react";
import {debounce, classList} from "../../../utils";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import {NavLink} from "react-router-dom";
import ScrollTo from "../../common/ScrollTo";
import {Button, useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {makeStyles} from "@material-ui/core/styles";
import modalContext from "../../modal-context";
import CountDownComponents from "./CountDownComponents";
import Sparkle from 'react-sparkle'


const useStyles = makeStyles(({palette, ...theme}) => ({
    pc_logo: {
        width: "50px",
        height: "50px",
        maxHeight: "50px"
    },
    register: {
        position: 'relative',
        cursor: 'pointer', 
    },
    register_child : {
        display: 'none',
        position: 'absolute',
        background: "linear-gradient(to right, #fdc830, #f37335)",
        borderRadius: '5px',
        top: '30px',
    },
    reg_child_child: {
        width: '160px',
        paddingLeft: '10px',
        marginLeft: '-50px',
    },
    register_hovered: {
        '&:hover $register_child': {
            display: 'block', 
        }
    }
}));


const TopBar4 = (props) => {
    const [isTop, setIsTop] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let scrollableElement = document.querySelector(".scrollable-content");
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;
    let toggleIcon = isClosed ? "menu" : "close";

    const handleScroll = () => {
        return debounce(({target: {scrollTop}}) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const classes = useStyles()

    const close = () => {
        setIsClosed(false);
    };

    function refreshPage() {
        window.location.href = '/help';
    }

    useEffect(() => {
        if (isMobile) {
            setIsClosed(true);
        }
    }, [isMobile]);

    useEffect(() => {
        if (!isMobile) {
            setIsClosed(false);
        }
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);


    const {handleOpenModal} = useContext(modalContext)

    return (
        <section
            className={classList({
                header: true,
                "bg-transparent": isTop && !isMobile,
                "header-fixed": !isTop,
                closed: isClosed,
            })}
        >
            <div className="container header-container">
                <div className="">
                    <a href={"/"}> <img src="/assets/images/cs/chinmaya_logo.png" alt="" className={classes.pc_logo} /></a>
                </div>
                {!props.otherMenus ?
                    <div className="float-right">
                        <ul className="navigation">
                            <li onClick={() => {
                                    setIsClosed(!isClosed);
                                }}>
                                <ScrollTo to="intro4" onScroll={close}  >
                                    Home
                                </ScrollTo>
                            </li>
                           
                            <li className={`${classes.register} ${classes.register_hovered}`} >
                                <a>
                                Register
                                </a>
                                <ul className={classes.register_child} >
                                    <li className={classes.reg_child_child} >
                                        <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSe87T0CHxUKi9IfLvnVG8NRvmsN47FT6mP78zyYKflRT6hJHg/viewform">
                                         Event Register
                                        </a>
                                    </li>
                                    <li className={classes.reg_child_child}>
                                        <a target="_blank" href="https://forms.gle/nSWxYDK1TgZXcUXU9">
                                         Staff Register
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            
                            <li>
                                <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSegKqkVSaFtF6pjOWvDgr9E6gM1LCfJxbs7mlocfeghA1_QwQ/viewform">
                                Offering
                                </a>
                            </li>

                            <li>
                                <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScUBjXuZ8vyaaEq2xHJ0RdNNHRD8PgjsLaffX250lPwvXL-4A/viewform">
                                Hotel list
                                </a>
                            </li>
                           
                            <li>
                                <a href="/event">
                                    Events
                                </a>
                            </li>

                            <li onClick={() => {
                                    handleOpenModal()
                                }}>
                                <a style={{cursor: "pointer"}} >
                                Tentative Program Details
                                </a>
                            </li>



                            <li>
                                <a href="/faq">
                                    FAQ
                                </a>
                            </li>


                            <li>
                                <a href="/aboutus">
                                    About us
                                </a>
                            </li>

                            <li>
                                <a>
                                    <div style={{position: "relative"}} >
                                        <Sparkle
                                            color={'white'}
                                            maxSize={5}
                                            flickerSpeed={'slower'}
                                            count={10}
                                        />
                                        <CountDownComponents/>
                                    </div>
                               
                                </a>
                            </li>


                        </ul>
                    </div>
                    :
                    <div className="float-right">
                        <ul className="navigation">
                            <li>
                                <NavLink onScroll={close} to={"/"}>Home</NavLink>
                            </li>
                            <li>
                                <NavLink onScroll={close} to={"/terms"}>Terms of Use</NavLink>
                            </li>
                            <li>
                                <NavLink onScroll={close} to={"/privacy-policy"}>Privacy Policy</NavLink>
                            </li>
                        </ul>
                    </div>
                }
                <div className="m-auto"/>
                <IconButton
                    className="header__toggle"
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    <Icon>{toggleIcon}</Icon>
                </IconButton>
            </div>
        </section>
    );
};

export default TopBar4;
