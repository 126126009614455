import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon, Box, Typography, AppBar, Tabs, Tab } from "@material-ui/core";
import Feature4 from "./Features4";
import { TouchAppOutlined, YoutubeSearchedFor } from "@material-ui/icons";
import FacebookIcon from "../../common/icons/FacebookIcon";
import { Gallery } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  
  


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#fff",
      },
  para_style: {
    textAlign: "justify",
  },
  fontHeading: {
    marginTop: "-40px",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      textAlign: "center",
      fontSize: "28px",
    },
  },
  musicImage: {
    width: "362px",
    borderRadius: "10px",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  orangeHeading: {
    color: "#F46A00",
    fontSize: "24px",
     // Adjust the size as needed
  },
  fontHeading2: {
    fontSize: "18px",
    color: "#F46A00",
    marginTop: "30px"
  },

  fontHeading3: {
    fontSize: "15px",
    color: "#F46A00"
  },

  website: {
    textDecoration: "underline",
    color: "#F46A00"
  },


}));


const MASTERIMG = [
  {id: 1, src: "/assets/images/cs/fevent1.jpeg", original: "/assets/images/cs/fevent1.jpeg", width: 770, height: 812},
  {id: 1, src: "/assets/images/events/1.jpeg", original: "/assets/images/events/1.jpeg", width: 1070, height: 812},
  {id: 1, src: "/assets/images/events/2.jpeg", original: "/assets/images/events/2.jpeg", width: 970, height: 812},
  {id: 1, src: "/assets/images/events/3.jpeg", original: "/assets/images/events/3.jpeg", width: 1070, height: 812},
  {id: 1, src: "/assets/images/events/4.jpeg", original: "/assets/images/events/4.jpeg", width: 970, height: 812},
  {id: 1, src: "/assets/images/events/5.jpeg", original: "/assets/images/events/5.jpeg", width: 1070, height: 812},
  {id: 1, src: "/assets/images/events/6.jpeg", original: "/assets/images/events/6.jpeg", width: 870, height: 812},
  {id: 1, src: "/assets/images/events/7.jpeg", original: "/assets/images/events/7.jpeg", width: 970, height: 812},
  {id: 1, src: "/assets/images/events/9.jpeg", original: "/assets/images/events/9.jpeg", width: 1070, height: 812},
  {id: 1, src: "/assets/images/events/10.jpeg", original: "/assets/images/events/10.jpeg", width: 1070, height: 812},
  {id: 1, src: "/assets/images/events/11.jpeg", original: "/assets/images/events/11.jpeg", width: 1090, height: 812},
  {id: 1, src: "/assets/images/events/8.jpeg", original: "/assets/images/events/8.jpeg", width: 1090, height: 812},
]

const IMG = [
 
    {
        src: "/assets/images/inaguration/1.jpeg",
        original: "/assets/images/inaguration/1.jpeg",
        width: 500,
        height: 312,
      },
      {
        src: "/assets/images/inaguration/2.jpeg",
        original: "/assets/images/inaguration/2.jpeg",
        width: 500,
        height: 312,
      },
      {
        src: "/assets/images/inaguration/3.jpeg",
        original: "/assets/images/inaguration/3.jpeg",
        width: 500,
        height: 312,
      },
      {
        src: "/assets/images/inaguration/3.jpeg",
        original: "/assets/images/inaguration/3.jpeg",
        width: 500,
        height: 312,
      },
   
   



  ]

  const FIMG = [
    {}
  ]

const Event = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

// current image of inaguration

  const [index, setIndex] = useState(-1);

  const currentImage = IMG[index];
  const nextIndex = (index + 1) % IMG.length;
  const nextImage = IMG[nextIndex] || currentImage;
  const prevIndex = (index + IMG.length - 1) % IMG.length;
  const prevImage = IMG[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

// future events

  const [findex, fsetIndex] = useState(-1);

  const fcurrentImage = FIMG[findex];
  const fnextIndex = (findex + 1) % FIMG.length;
  const fnextImage = FIMG[fnextIndex] || fcurrentImage;
  const fprevIndex = (findex + FIMG.length - 1) % FIMG.length;
  const fprevImage = FIMG[fprevIndex] || fcurrentImage;

  const fhandleClick = (index, item) => fsetIndex(index);
  const fhandleClose = () => fsetIndex(-1);
  const fhandleMovePrev = () => fsetIndex(fprevImage);
  const fhandleMoveNext = () => fsetIndex(fnextIndex);

// master life events

  const [Mindex, MsetIndex] = useState(-1);

  const McurrentImage = MASTERIMG[Mindex];
  const MnextIndex = (Mindex + 1) % MASTERIMG.length;
  const MnextImage = MASTERIMG[MnextIndex] || McurrentImage;
  const MprevIndex = (Mindex + MASTERIMG.length - 1) % MASTERIMG.length;
  const MprevImage = MASTERIMG[MprevIndex] || McurrentImage;

  const MhandleClick = (index, item) => MsetIndex(index);
  const MhandleClose = () => MsetIndex(-1);
  const MhandleMovePrev = () => MsetIndex(MprevImage);
  const MhandleMoveNext = () => MsetIndex(MnextIndex);

  const VID = [
    {id: 8,src: "https://img.youtube.com/vi/Tt9c_nTtVcU/hqdefault.jpg", thumb: "https://youtu.be/Tt9c_nTtVcU?si=g4s7i7SlzXhH5pgg"},
    {id: 9,src: "https://img.youtube.com/vi/ZzJu2n9wvx0/hqdefault.jpg", thumb: "https://youtu.be/ZzJu2n9wvx0?si=INHCkweRCquRo5kz"},
    {id: 1,src: "https://img.youtube.com/vi/EePS6MBmsvI/hqdefault.jpg", thumb: "https://youtu.be/EePS6MBmsvI?si=kza5fVS4U2V-aypK"},
    {id: 2,src: "https://img.youtube.com/vi/9TUE_OjufpA/hqdefault.jpg", thumb: "https://youtu.be/9TUE_OjufpA?si=pdWBhuHLC8sj_InS"},
    {id: 4,src: "https://img.youtube.com/vi/eGkhMZdBPBA/hqdefault.jpg", thumb: "https://youtu.be/eGkhMZdBPBA?si=0UDsHtm7AaF9-eHS"},
    {id: 5,src: "https://img.youtube.com/vi/UiH2P-FR2mI/hqdefault.jpg", thumb: "https://youtu.be/UiH2P-FR2mI?si=oydvMWzkYTxZPUPx"},
    {id: 6,src: "https://img.youtube.com/vi/dIdgYCfOQkI/hqdefault.jpg", thumb: "https://youtu.be/dIdgYCfOQkI?si=N3TIBfjVOMbPr__C"},
    {id: 7,src: "https://img.youtube.com/vi/zEoUKcPucK8/hqdefault.jpg", thumb: "https://youtu.be/zEoUKcPucK8?si=TJU7z1x0ZOJizDvd"},
    {id: 8,src: "https://img.youtube.com/vi/pP2T5Digz4M/hqdefault.jpg", thumb: "https://youtu.be/pP2T5Digz4M?si=TJU7z1x0ZOJizDvd"},
]

  const history = useHistory();

 

  const redirectToAnotherPage = (index) => {
    const video =  VID[index]

    window.location.href = video.thumb
    
  };

  return (
    <section className="section section-intro mt-2" id="intro4">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading}>
                 Events
                </h1>
              </p>
              <p className={`max-w-500 mb-8${classes.para_style} `}>
              You can explore both past and upcoming events through the tabs below, allowing you to savor glimpses of the events. <span style={{color: "#F46A00"}} > [Click <TouchAppOutlined style={{marginBottom: "-5px"}} /> on images and videos to access a larger view within the event  .]</span>
              </p>
              <AppBar style={{borderRadius: "10px", padding: "10px"}} position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Future Events" {...a11yProps(0)} />
                    <Tab label="Past Events" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                        <div className="ml-5 mt-10" >
                            <h1 className={classes.fontHeading3}>
                              {/* <li>Events</li>  */}
                            </h1>
                            <Gallery 
                                margin={8}
                                images={FIMG} 
                                onClick={fhandleClick}
                                enableImageSelection={false}
                            />
                        </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <div>
                       <h1 className={classes.fontHeading2}>
                            Master Gita Master life - By Swami Advayananda
                        </h1>
                        {/* <p className={`max-w-500 mb-8${classes.para_style} `}>
                          A hundred and eight years ago, a star rose on the Ernakulam horizon in the home of Parukutty amma and Kuttan Menon. The little Balakrishna Menon would re-ignite the fire of Vedanta once again, after Sri Adi Sankara did in 800 A.D and more recently, Swami Vivekananda in the 19th century.
                        </p>
                        <p className={`max-w-500 mb-8${classes.para_style} `}>
                          <span style={{color: "#F46A00"}} >Swami Chinmayananda</span>  as He was blessed by his diksha Guru, Swami Sivananda  heralded a great new era for a people who had become incapable of accessing our scriptures that were predominantly in Sanskrit. And Swami Chinmayananda began to teach the Upanishads and the Gita in English. This was the star that rose rapidly on the Indian horizon, enrapturing the masses with the knowledge of the scriptures at a time when confusion had once more diseased the people.
                        </p>
                        <p className={`max-w-500 mb-8${classes.para_style} `}>
                        If Sri Adi Sankara, in his 32 years, gave direction to a nation that was mired in confusion over a plethora of philosophies, bringing the Shanmatha system, and thus the Oneness of all deities and the convergence into Advaita, Swami Vivekananda is credited with directing the people who practiced some form of religion, but without the logic and backing of Vedanta philosophy. And like Sankara before him, brought Advaita to the forefront.
                         </p>
                         <p className={`max-w-500 mb-8${classes.para_style} `}>
                         It was 14 years later, in 1916, that the Advaita star rose once again, continuing the social engineering that the two greats before Him had heralded: Swami Chinmayananda.
                         </p>
                         <p className={`max-w-500 mb-8${classes.para_style} `}>
                         We hold in great reverence the anvil on which our Vedanta is anchored, and which our own Gurudev held out to us as our legacy. And this legacy is what forms the hub of all Vedanta preached and practiced in the Chinmaya Mission: Sri Adi Sankara.
                         </p>
                         <p className={`max-w-500 mb-8${classes.para_style} `}>
                         On the 8th of May 2024, Chinmaya Seva Trust, Kerala will celebrate Gurudev, Swami Chinmayananda’s 108th Jayanti, supported by CCMT and all Mission Centres. To be called “Chinmaya Sankaram 2024” this five-day programme ending on 12th May 2024, will glorify the life and times of Sri Sankara and Gurudev and their herculean efforts at strengthening  Advaita among mankind.
                         </p>
                         <p className={`max-w-500 mb-8${classes.para_style} `}>
                         To be held in the august presence of Pujya Guruji Swami Tejomayananda and Swami Swaroopananda, it will be replete with lectures, Cultural Programmes, Gayathri Havan, talks by Acharyas and other learned scholars, Yathi Puja of 108 Sannyasins, Nagarasankeerthanam, Mass Bhagavad-Gita and Saundarya Lahari Parayanam, special celebrations at Sri Sankara's birthplace in Adi Sankara Nilayam, and many more.
                        </p> */}
                        <p className={`max-w-500 mb-8${classes.para_style} `}>
                        As part of the Chinmaya Sankaram 2024 initiative, Chinmaya Seva Trust Kerala and Chinmaya Mission Kerala Region organized a Bhagavad-Gita Jnana Yajna by Swami Advayanandaji from  9th to 13th November 2023 at Chinmaya Viswa Vidyapeeth Warriam Road facility. The Yajna focused on the theme "Master Gita, Master Life," and Shri RV Jayakumar, Secretary of Bharatheeya Vidyanikethan, Kerala State, inaugurated the Gita Jnana Yajna. Over the course of five days, Swamiji explained how to master life through the techniques mentioned in the Bhagavad Gita. The well-attended talk series concluded on 13th of November, and the members of the Chinmaya Sankaram Core Committee offered Gurudakshina to Swamiji.
                         </p>


                        <div className="ml-5 mt-10" >
                            <h1 className={classes.fontHeading3}>
                              <li>Images</li> 
                            </h1>
                            <Gallery 
                                margin={8}
                                images={MASTERIMG} 
                                onClick={MhandleClick}
                                enableImageSelection={false}
                            />
                        </div>
                        <h1 className={classes.fontHeading2}>
                            Launch Event
                        </h1>
                        <div className="ml-5 mt-10" >
                            <h1 className={classes.fontHeading3}>
                              <li>Images</li> 
                            </h1>
                            <Gallery 
                                margin={8}
                                images={IMG} 
                                onClick={handleClick}
                                enableImageSelection={false}
                            />
                        </div>
                       
                        <div className="ml-5 mt-10" >
                            <h1 className={classes.fontHeading3}>
                               <li>Videos</li> 
                            </h1>
                            <Gallery 
                                margin={8}
                                images={VID} 
                                onClick={redirectToAnotherPage}
                                enableImageSelection={false}
                            />
                        </div>
                    </div>
                    
                </TabPanel>
                
            </div>
          </Grid>
        </Grid>
      </div>
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}

{!!fcurrentImage && (
        <Lightbox
          mainSrc={fcurrentImage.original}
          imageTitle={fcurrentImage.caption}
          mainSrcThumbnail={fcurrentImage.src}
          nextSrc={fnextImage.original}
          nextSrcThumbnail={fnextImage.src}
          prevSrc={fnextImage.original}
          prevSrcThumbnail={fnextImage.src}
          onCloseRequest={fhandleClose}
          onMovePrevRequest={fhandleMovePrev}
          onMoveNextRequest={fhandleMoveNext}
        />
      )}

{!!McurrentImage && (
        <Lightbox
          mainSrc={McurrentImage.original}
          imageTitle={McurrentImage.caption}
          mainSrcThumbnail={McurrentImage.src}
          nextSrc={MnextImage.original}
          nextSrcThumbnail={MnextImage.src}
          prevSrc={MnextImage.original}
          prevSrcThumbnail={MnextImage.src}
          onCloseRequest={MhandleClose}
          onMovePrevRequest={MhandleMovePrev}
          onMoveNextRequest={MhandleMoveNext}
        />
      )}
    </section>
  );
};

export default Event;
