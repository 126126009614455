import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, useMediaQuery} from "@material-ui/core";
import Feature4IconCreator from "../../common/icons/Feature4IconCreator";
import GetAppIcon from '@material-ui/icons/GetApp';
import CubeIcon from "../../common/icons/CubeIcon"
import Group from '@material-ui/icons/Group';
import SportsBaseballIcon from '@material-ui/icons/SportsBaseball';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PieChart from '@material-ui/icons/PieChart';
import HourglassEmpty from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Lightbox from "react-image-lightbox";

const useStyles = makeStyles((theme) => ({
    feature: {
        [theme.breakpoints.down("xs")]: {
            textAlign: "center !important",
        },
    },

    smallHide: {
        display: "none",
        [theme.breakpoints.down("xs")]: {
           display: "block",
           marginTop: "50px"
        },
    },

    largeHide: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
           display: "none"
        },
    },


    name: {
        width: "200px",
        textAlign: "center",
        marginLeft: "-30px",
        [theme.breakpoints.down("xs")]: {
            width: "200px",
            margin: "auto",
            marginLeft: "auto"
         },
    },

    marginOfSadhus: {
        
        marginLeft: "20px",
        
        [theme.breakpoints.down("xs")]: {
            marginTop: "20px",
            marginLeft: "0px",
            marginBottom: "30px",
         },
         "@media screen and (max-width: 778px) and (min-width: 501px)" :{
            marginLeft: "0px",
            marginTop: "30px",
        }
    },

    musicImageTop: {
        width: "362px",
        borderRadius: "20px",
        height: "472px",
        marginTop: "-40px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        
        "@media screen and (max-width: 950px) and (min-width: 501px)": {
            width: "302px",
        },
    },

    musicImage: {
        width: "362px",
        borderRadius: "20px",
        height: "472px",
        marginTop: "100px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
        
        "@media screen and (max-width: 950px) and (min-width: 501px)": {
            width: "302px",
        },
    },

    acharayas: {
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        objectFit: "cover",
        cursor: "pointer"
    },

    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-95px"
        },
    },

    seeAllButton: {
        display: "flex",
        backgroundColor: "#fff",
        border: "1px solid #F46A00 " ,
        color: "#F46A00", 
        borderRadius: "10px",
        marginBottom: "60px",
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
          padding: "5px 50px",
      },
        "@media screen and (max-width: 778px) and (min-width: 501px)" :{
            marginLeft: "25px",
        }
    
    },

    musicImageWrapper: {
        position: "relative",
        textAlign: "right",

        // marginLeft: "100px",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "81%",
            top: "18%",
            left: "105px",
            right: 0,
            margin: "0 -2000px 0 -80px",
            borderRadius: "300px",
            background: "transperent",
            zIndex: -1,
            opacity: 0.1,

            [theme.breakpoints.down("xs")]: {
                display: "none",
            },
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "calc(10% + 20px)",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("xs")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },

        "@media screen and (max-width: 950px) and (min-width: 501px)": {
            left: "10px",
        },
    },
}));


const IMAGES = [
    {id: 1 , img: "/assets/images/acharyas/s1.jpg" , name: "Swami Viviktananda Saraswati"},
    {id: 2 , img: "/assets/images/acharyas/Ns2.jpg" , name: "Acharya Atma Chaitanya"},
    {id: 3 , img: "/assets/images/acharyas/Ns3.jpg" , name: "Swami Gabheerananda Saraswathi"},
    {id: 4 , img: "/assets/images/acharyas/s6.jpg" , name: "Swami Asheshananda Saraswathy"},
    {id: 5 , img: "/assets/images/acharyas/s4.jpg" , name: "Swami Satyananda Saraswathy"},
    {id: 6 , img: "/assets/images/acharyas/Ns4.jpg" , name: "Swami Viswananda Saraswathy"},
    {id: 7 , img: "/assets/images/acharyas/Ns8.jpeg" , name: "Swami Tattvananda Saraswathy"},
    {id: 8 , img: "./assets/images/acharyas/SB.jpeg" , name: "Swami Shabreeshananda Saraswathy"},
    {id: 9 , img: "/assets/images/acharyas/Ns6.jpg" , name: "Swamini Samhitananda Saraswathy"},
    {id: 10 , img: "/assets/images/acharyas/s2.jpg" , name: "Swami Jitatmananda"},
    {id: 11 , img: "/assets/images/acharyas/Ns7.jpg" , name: "Swami Sharadananda Saraswathy"},
    {id: 12 , img: "/assets/images/acharyas/B1.jpg" , name: "Brahmachari Mukunda Chaitanya"},
    {id: 13 , img: "/assets/images/acharyas/B2.jpg" , name: "Brahmacharini Devaki Chaitanya"},
    {id: 14 , img: "/assets/images/acharyas/B3.jpg" , name: "Brahmachari Sudheer Chaitanya"},
    {id: 15 , img: "/assets/images/acharyas/B4.jpg" , name: "Brahmachari Akhilesh Chaitanya"},
    {id: 17 , img: "/assets/images/acharyas/M2.jpg" , name: "Brahmachari Sudheesh"},
    {id: 18 , img: "/assets/images/acharyas/BNIkil.jpeg" , name: "Brahmachari Nikhil"},
    {id: 19 , img: "/assets/images/acharyas/ph.jpg" , name: "Brahmachari Sudheer"},
    {id: 20 , img: "/assets/images/acharyas/M1.jpg" , name: "Brahmacharini Rojisha"},

]

const Feature4 = () => {
    const classes = useStyles();

    const [index, setIndex] = useState(-1);

    const currentImage = IMAGES[index];
    const nextIndex = (index + 1) % IMAGES.length;
    const nextImage = IMAGES[nextIndex] || currentImage;
    const prevIndex = (index + IMAGES.length - 1) % IMAGES.length;
    const prevImage = IMAGES[prevIndex] || currentImage;
  
    const handleClick = (index, item) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
  


    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className={`${classes.container_top} section`} id="feature4">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="center">
                    <Grid item lg={12} md={6} sm={6} xs={12}>
                        <div className="max-w-450 mb-16">
                            <h1 className="text-primary mt-10 font-normal text-40">Sadhus</h1>
                            <p>
                                Sadhus Chinmaya Mission Centres in Kerala
                            </p>
                        </div>

            

                        <Grid container spacing={isMobile ? 3 : 10}>

                            {
                                IMAGES.map((img, index) => (
                                        <Grid
                                            item
                                            lg={3}
                                            md={6}
                                            sm={6}
                                            xs={12}
                                            className={classes.feature}
                                        >
                                            <div className="relative mx-4">
                                                <Feature4IconCreator>
                                                    <img onClick={() => handleClick(index, [])} src={img.img} alt="image" className={classes.acharayas} />
                                                </Feature4IconCreator>
                                            </div>
                                            <div>
                                                <h6 className={` ${classes.name}  mt-8 font-normal mb-0 pointer `}>{img.name}</h6>
                                            </div>
                                        </Grid>
                                ))
                            }
                            


                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.img}
          imageTitle={currentImage.name}
          mainSrcThumbnail={currentImage.img}
          nextSrc={nextImage.img}
          nextSrcThumbnail={nextImage.img}
          prevSrc={prevImage.img}
          prevSrcThumbnail={prevImage.img}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
        </section>
    );
};

export default Feature4;
