import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({

    

}));

const data = {
    title: "FAQ",
    rows: [
        {
            title: "1. What is the eligibility to register for the Chinmaya Sankaram 2024?",
            content: `Anyone interested can register for Chinmaya Sankram. Children under the age of five do not need to register.`,
        },
        {
            title: "2. Is team registration possible?",
            content:
                "Yes, it’s possible. But they have to submit their individual details. Option you will find in the Chinmaya Sankaram Registration App.",
        },
        {
            title: "3. How are the sessions structured?",
            content: `In Chinmaya Sankaram, we planned events that catered to all kinds of people. There will be participatory pujas, Havans and discourses on Sri Adi Sankaracharya, Pujya Gurudev Swami Chinmayananda, and Bhagavad-Gita by eminent speakers. There will be cultural sessions, Yathi puja, and Sannyasi Sammelan as well. There will be mass chanting of Bhagavad-Gita and Saundarya Lahari. Chinmaya Sankaram is a mix of all kinds of programmes related to the grandeur of our culture. `,
        },
        {
            title: "4. What is the medium of language?",
            content: `Mainly, the programmes will be in English only.`,
        },
        {
            title: "5. What is the camp donation amount?",
            content: `Rs. 3500/- is the Chinmaya Sankaram donation per delegate. Early bird registration up to 31st January, 2024 is Rs. 3000/-. Please click the below link to register and pay donation.`,
        },
        {
            title: "6. What is the last date for registration?",
            content: `The last date of registration is 1st May, 2024. But you can avail of the early bird discount only up to 31st January, 2024.`,
        },
        {
            title: "7. What are all the things I should bring during the programme?",
            content: `Other than your personal things, like sufficient dresses and personal medicines, if needed, please bring your Aadhaar card or Voter ID.`,
        },
        {
            title: "8. Does the donation is refundable?",
            content: "No, there are no refund policies for the donations."
            },
            {
            title: "9. What are the facilities provided?",
            content: "a) Camp kit.\nb) Food and snacks for all the days of Chinmaya Sankaram.\nc) Transport to Adi Sankara Nilayam from the main avenue on the Adi Sankara Jayathi day and back"
            },
            {
            title: "10. Is it compulsory to attend all the 5 days?",
            content: "Being present for the magnificent event in full is preferable. After that, the choice is yours."
            },
            {
            title: "11. Are Pujya Guruji Swami Tejomayanadaji and Pujya Swami Swaroopanandaji present at the programme?",
            content: "Yes. Chinmaya Sankaram 2024 would be attended by Pujya Guruji Swami Tejomayanadaji and Pujya Swami Swaroopanandaji."
            },
            {
            title: "12. Will the organizers provide accommodation?",
            content: "Participants have to manage their own accommodations. You can book nearby hotels for accommodation. A hotel list is provided in the Chinmaya Sankaram Registration App. Anyway, we are arranging dormitory accommodations in our Chinmaya Vidyalaya as well. For that, please contact the accommodation team at 9539333890."
            },
            {
            title: "13. Can I stay at CIF or Ernakulam after Chinmaya Sankaram 2024?",
            content: "After 12th May, 2024, the Chinmaya Sankaram Team will no longer be hosting any events. However, participants may choose to stay back. They need to make arrangements for their lodging and food. Regarding your stay at CIF, kindly contact office@chinfo.org or 9539333890 for more details."
            },
            {
            title: "14. Will transportation be arranged for local movement?",
            content: "There won’t be any special transportation for the participants. Kindly choose the hotels nearby for your stay. We will be arranging transportation from dormitory accommodations in Chinmaya Vidyalayas to the main venue. Please contact 9539333890 for more details."
            },
            {
            title: "15. Can we offer puja or other needs?",
            content: "Yes, you can offer for Bhiksha and become puja Yajamans. Kindly find the link for the same."
            },
            {
            title: "16. Is there a travel desk to help us with travel planning?",
            content: "Yes, you can contact our travel desk for travel planning."
            },
            {
            title: "17. Can I volunteer in Chinmaya Sankaram 2024?",
            content: "Yes, if you are interested in doing seva during the Chinmaya Sankaram 2024, kindly mention it during your registration."
            },
            {
            title: "18. Can I get a route map for programme venues?",
            content: "The main venue is the Ernakulathappan Temple Ground which is 30 km (1.5 hr) from Kochi International Airport, 1 km (5 min) from Ernakulam Jn Railway Station, 4 km (15 min) from Ernakulam Town Railway Station, 6 km (20 min) from Vyttila Bus Mobility Hub and 2 km (10 min) from Ernakulam KSRTC Bus Station."
            },
            {
            title: "19. What are the famous places to visit in and around Ernakulam?",
            content: "Link for the travel guide and places. In case you require any assistance, kindly contact our Travel desk."
            },
            {
            title: "20. Can organizers assist in planning local sight-seeing?",
            content: "In case you require any assistance, after Chinmaya Sankaram 2024, kindly contact our Travel desk."
            },
            {
            title: "21. Is there a chance I can participate in the cultural events of Chinmaya Sankaram?",
            content: "The cultural events are scheduled in advance. Additionally, there are activities that include participation of Delegates that you can take part in."
            },
            {
            title: "22. Is there any first-aid or medical support available here?",
            content: "Yes, we will have the service of a medical team here. Please bring your medicines/medical documents, if any, for personal use."
            },
            {
            title: "23. Is there a chance we can shop for souvenirs?",
            content: "Yes, Chinmaya Vani and Chinmaya Upahar outlets will be part of Chinmaya Sankaram."
            },
            {
            title: "24. Is there a child care facility available (Creche)?",
            content: "No. We won't have any facilities for taking care of children. Parents have to make the necessary arrangements."
            },
            {
            title: "25. Any Wi-Fi facilities available?",
            content: "No. There won't be any Wi-Fi facilities available. The program is happening in the City Centre, so there won't be any network issues with service providers."
            },
            {
            title: "26. Contact details in emergency?",
            content: "Please contact/WhatsApp M. Kuttikrishnan Nair, Program Manager, CMECT-GC, 9539333890 for more details"
            }
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "#F46A00",
    rowTitleColor: "#F46A00",
    // rowContentColor: 'grey',
    arrowColor: "#F46A00",
    
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
};

const FAQ = () => {

    const classes = useStyles();


    return (
        <section className="section section-intro" id="faq">
            <div className="container">
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
            </div>
        </section>
    );
};

export default FAQ;
