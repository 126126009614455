import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon } from "@material-ui/core";
import { Gallery } from "react-grid-gallery";
import { IMG } from "./IMG";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useState } from "react";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  para_style: {
    textAlign: "justify",
  },
  fontHeading: {
    marginTop: "-40px",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      textAlign: "center",
      fontSize: "20px",
    },
  },
  musicImage: {
    width: "362px",
    borderRadius: "10px",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  orangeHeading: {
    color: "#F46A00",
    fontSize: "24px", // Adjust the size as needed
  },
  imageCover: {
    objectFit: 'cover',
  },
}));





const GalleryMain = () => {
  const [index, setIndex] = useState(-1);

  const currentImage = IMG[index];
  const nextIndex = (index + 1) % IMG.length;
  const nextImage = IMG[nextIndex] || currentImage;
  const prevIndex = (index + IMG.length - 1) % IMG.length;
  const prevImage = IMG[prevIndex] || currentImage;

  const handleClick = (index, item) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const classes = useStyles();

 

  return (
    <section className="section section-intro mt-2" id="intro4">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p className="text-primary m-0 mb-4 text-20">
                <h1 className={classes.fontHeading} style={{ position: "absolute" }}>
                Glimpses of Chinmaya Birth Centenary Celebrations 2015.
                </h1>
              </p>
              <Gallery 
                margin={8}
                images={IMG} 
                onClick={handleClick}
                enableImageSelection={false}
               />
            </div>
          </Grid>
        </Grid>
      </div>
      {!!currentImage && (
        <Lightbox
          mainSrc={currentImage.original}
          imageTitle={currentImage.caption}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.original}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.original}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </section>
  );
};

export default GalleryMain;
