import React, {useEffect} from "react";
import {scrollTo} from "../utils";
import Footer2 from "./sections/cs/Footer2";
import AboutMain from "./sections/AboutMain";
import TopAbout from "./sections/cs/TopAbout";
import { useMediaQuery } from "@material-ui/core";
import Banner1 from "./sections/Banner1";
import Aboutus from "./sections/cs/Aboutus";
import Event from "./sections/cs/Event";

const IntroEvent = () => {
    useEffect(() => {
        scrollTo("root");
    });

    const mobile = useMediaQuery("(max-width: 768px");

    return (
        <div className="landing">
             <TopAbout/>
             <Banner1/>
            <Event/>
            <Footer2/>
        </div>
    );
};

export default IntroEvent;
