import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon } from "@material-ui/core";
import Feature4 from "./Features4";
import { YoutubeSearchedFor } from "@material-ui/icons";
import FacebookIcon from "../../common/icons/FacebookIcon";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  para_style: {
    textAlign: "justify",
  },
  fontHeading: {
    marginTop: "-40px",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      textAlign: "center",
      fontSize: "28px",
    },
  },
  musicImage: {
    width: "362px",
    borderRadius: "10px",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  orangeHeading: {
    color: "#F46A00",
    fontSize: "24px", // Adjust the size as needed
  },
  fontHeading2: {
    fontSize: "18px"
  },

  website: {
    textDecoration: "underline",
    color: "#F46A00"
  }
}));

const Aboutus = () => {
  const classes = useStyles();

  return (
    <section className="section section-intro mt-2" id="intro4">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading}>
                  About us
                </h1>
              </p>
              <p className={`max-w-500 mb-8${classes.para_style} `}>
              Chinmaya Seva Trust Kerala provides co-ordination and guidance for spiritual activities of the Chinmaya Mission Centres in Kerala spread over almost all the districts of Kerala.
              </p>

              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading2}>
                Chinmaya Movement
                </h1>
              </p>
              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              Chinmaya Mission is a World Wide Movement with the noble aim of spreading the time tested wisdom of the ancient scriptures of India contained in the Vedas, Upanishads and the Bhagavad Geeta. The spiritual activities of the Mission are aimed at imparting the knowledge at all stages of life, through Balavihars for children, Yuva Kendras for youngsters, Study Groups for elders, Devi Groups for ladies and Vanaprasth Sansthan for senior citizens. The Mission work started with the Geeta Gnana Yagnas of Param Pujya Swami Chinmayanandaji, which has been taken up thereafter and are being carried out since then by the educated and dedicated group of disciples comprising both sanyasins and house holders. At the social level, the Mission activities have been reflected in various fields, eminently in the Chinmaya Vidyalayas and Colleges which have high standards of performance and discipline, hospitals, health training institutions, rural development and humanitarian services especially during calamities, Vedantha and Sanskrit research and many such areas. The hall mark of the Vidyalayas is the Chinmaya Vision Programme emphasizing a) the Integrated Development of body, mind and intellect of the children b) Indian culture c) Patriotism and d) Universal outlook. This worldwide movement is at present headed by Sampujya Swami Tejomayanandaji.
              </p>

              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading2}>
                Vision
                </h1>
              </p>
              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              To give maximum happiness to maximum people for maximum time.
              </p>

              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading2}>
                Pledge
                </h1>
              </p>
              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              We stand as one family, bound to each other with love and respect. We serve as an army,encouraged and disciplined ,ever ready to fight against all low tendencies and false values within and without us. We live honestly , the noble life of sacrifice and service, producing more than what we consume and giving more than what we take. We see Lords grace to keep us on the path of virtue, courage and wisdom. May thy grace and blessings flow through us to the world around us. We believe that the service of our country is the service of the Lord of Lords and the devotion to people is the devotion to the Supreme self. We know our responsibilities . Give us the ability and courage to fulfill them
              </p>

              <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading2}>
                Gurudev
                </h1>
              </p>
              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              His Spirits live on....in the hands and hearts of thousands who today offer love and service to the Society, but would have never thought of looking beyond themselves, if not for the ennobling touch of Gurudev's work and life.
              </p>

              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              For further information visit our another website
                    <li className={`ml-10 mt-5 ${classes.website}`}  ><a target="_blank" href="http://www.chinmayamission.com">www.chinmayamission.com</a></li>
                    <li className={`ml-10  ${classes.website}`} ><a target="_blank" href="http://www.cstkerala.in/">www.cstkerala.in</a></li>
              </p>
              <p className={`max-w-500 mb-8  ${classes.para_style} `}>
              Connect with us
                    <li className={`ml-10 mt-5 ${classes.website}`}  ><a target="_blank" href="https://www.youtube.com/@ChinmayaChannel">Youtube</a></li>
                    <li className={`ml-10  ${classes.website}`} ><a target="_blank" href="https://www.facebook.com/people/Chinmaya-Sankaram-2024/61551502393158/?mibextid=9R9pXO">Facebook</a></li>
                    <li className={`ml-10  ${classes.website}`} ><a target="_blank" href="https://www.instagram.com/chinmayasankaram2024/?utm_source=qr&igshid=OGIxMTE0OTdkZA%3D%3D">Instagram</a></li>
                    <li className={`ml-10  ${classes.website}`} ><a target="_blank" href="https://twitter.com/chinmaya_108?t=VrGJziuWHdlV2iPqvhR-qQ&s=09">Twitter(X)</a></li>
              </p>
            </div>
            {/* Merge the event details here */}
            <div>
            <Feature4/>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default Aboutus;
