export const IMG = [
  {
    src: "/assets/images/gallery/IMG-20231026-WA0002.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0002.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0003.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0003.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0004.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0004.jpg",
    width: 200,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0005.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0005.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0006.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0006.jpg",
    width: 200,
    height: 183,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0007.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0007.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0008.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0008.jpg",
    width: 220,
    height: 248,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0041.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0041.jpg",
    width: 320,
    height: 313,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0010.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0010.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0011.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0011.jpg",
    width: 320,
    height: 194,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0012.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0012.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0013.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0013.jpg",
    width: 220,
    height: 113,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0014.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0014.jpg",
    width: 420,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0015.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0015.jpg",
    width: 257,
    height: 320,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0016.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0016.jpg",
    width: 226,
    height: 320,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0017.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0017.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0018.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0018.jpg",
    width: 220,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0019.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0019.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0020.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0020.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0021.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0021.jpg",
    width: 320,
    height: 183,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0022.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0022.jpg",
    width: 440,
    height: 320,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0046.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0046.jpg",
    width: 320,
    height: 190,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0023.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0023.jpg",
    width: 320,
    height: 148,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0047.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0047.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0024.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0024.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0025.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0025.jpg",
    width: 720,
    height: 320,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0026.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0026.jpg",
    width: 271,
    height: 320,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0027.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0027.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0028.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0028.jpg",
    width: 320,
    height: 215,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0029.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0029.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0030.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0030.jpg",
    width: 220,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0031.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0031.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0032.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0032.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0033.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0033.jpg",
    width: 320,
    height: 183,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0034.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0034.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0035.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0035.jpg",
    width: 320,
    height: 190,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0036.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0036.jpg",
    width: 320,
    height: 148,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0038.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0038.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0039.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0039.jpg",
    width: 320,
    height: 194,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0040.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0040.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0042.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0042.jpg",
    width: 320,
    height: 213,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0043.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0043.jpg",
    width: 320,
    height: 174,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0044.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0044.jpg",
    width: 320,
    height: 212,
  },
  {
    src: "/assets/images/gallery/IMG-20231026-WA0045.jpg",
    original: "/assets/images/gallery/IMG-20231026-WA0045.jpg",
    width: 320,
    height: 212,
  },
];
