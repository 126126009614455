import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button, Icon} from "@material-ui/core";
import AppleIcon from "../../common/icons/AppleIcon";
import ScrollEffect from "react-animate-on-scroll"

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import CardMedia from "@material-ui/core/CardMedia";
import ReactPlayer from "react-player";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const useStyles = makeStyles((theme) => ({

    para_style: {
        textAlign: "justify",
    },



    fontHeading: {
        marginTop: "-105px",
        marginLeft: "60px",
        fontSize: "80px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-105px",
            marginLeft: "50px",
            textAlign: "center",
            fontSize: "40px",
        },
        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
                marginLeft: "60px",
          }
    },

    themeText: {
        color: theme.palette.text.primary,
    },

    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-95px"
        },
    },


    musicImage: {
        width: "362px",
        marginTop: "-50px",
        marginLeft: "100px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginTop: "0px",
            marginLeft: "0px"
        },

        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
            width: "287px",
            marginLeft: "52px",
        }
    
    },
    video: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    modal: {
        zIndex: "999",
        padding: "10px"
    },
    musicImageWrapper: {
        position: "relative",
        display: "inline-block",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "80%",
            left: 0,
            right: 0,
            margin: "0 250px 40px -2000px",
            bottom: 200,
            borderRadius: "300px",
            background: theme.palette.primary.main,
            transformOrigin: "right bottom",
            transform: "rotate(135deg)",
            zIndex: -1,
            opacity: 0.1,
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "20%",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("xs")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },

       
      
        
    },
}));

const Intro4 = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <section className={`${classes.container_top} section section-intro`} id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    
                    <Grid style={{padding: "0px"}} item lg={6} md={6} sm={6} xs={12}>
                        <div>
                            <p  className="text-primary m-0 mb-4 text-20">
                                <img
                                    style={{borderRadius: "13px"}}
                                    width="50px"
                                    height="100px"
                                    src="/assets/images/cs/deepam.png"
                                    alt="app-icon"
                                />
                                <h1 className={classes.fontHeading} style={{position: "absolute"}}>Chinmaya Sankaram 2024</h1>
                            </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `} >
                                A hundred and eight years ago, a star rose on the Ernakulam horizon in the home of Parukutty amma and Kuttan Menon. The little Balakrishna Menon would re-ignite the fire of Vedanta once again, after Sri Adi Sankara did in 800 A.D and more recently, Swami Vivekananda in the 19th century.
                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Swami Chinmayananda as He was blessed by his diksha Guru, Swami Sivananda  heralded a great new era for a people who had become incapable of accessing our scriptures that were predominantly in Sanskrit. And Swami Chinmayananda began to teach the Upanishads and the Gita in English. This was the star that rose rapidly on the Indian horizon, enrapturing the masses with the knowledge of the scriptures at a time when confusion had once more diseased the people.
                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                If Sri Adi Sankara, in his 32 years, gave direction to a nation that was mired in confusion over a plethora of philosophies, bringing the Shanmatha system, and thus the Oneness of all deities and the convergence into Advaita, Swami Vivekananda is credited with directing the people who practiced some form of religion, but without the logic and backing of Vedanta philosophy. And like Sankara before him, brought Advaita to the forefront.

                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                It was 14 years later, in 1916, that the Advaita star rose once again, continuing the social engineering that the two greats before Him had heralded: Swami Chinmayananda.
                                </p>

                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                We hold in great reverence the anvil on which our Vedanta is anchored, and which our own Gurudev held out to us as our legacy. And this legacy is what forms the hub of all Vedanta preached and practiced in the Chinmaya Mission: Sri Adi Sankara.
                                </p>

                                 <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                 On the 8th of May 2024, Chinmaya Seva Trust, Kerala will celebrate Gurudev, Swami Chinmayananda’s 108th Jayanti, supported by CCMT and all Mission Centres. To be called “Chinmaya Sankaram 2024” this five-day programme ending on 12th May 2024, will glorify the life and times of Sri Sankara and Gurudev and their herculean efforts at strengthening  Advaita among mankind.

                                 </p>
                                 <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                 To be held in the august presence of Pujya Guruji Swami Tejomayananda and Swami Swaroopananda, it will be replete with lectures, Cultural Programmes, Gayathri Havan, talks by Acharyas and other learned scholars, Yathi Puja of 108 Sannyasins, Nagarasankeerthanam, Mass Bhagavad-Gita and Saundarya Lahari Parayanam, special celebrations at Sri Sankara's birthplace in Adi Sankara Nilayam, and many more.
                                 </p>
                                {/* <p className="max-w-500 ">
                                The 108th Gurudev Jayanti in 2024 has another special feature. The Jayanti of Shrimat Shankaracharya, the universal saint, falls right next to it – on 12th May. Chinmaya Mission Kerala Division has decided to celebrate the upcoming birthdays of these two unique spiritual giants born in Ernakulam district with due solemnity. Thus, from 8th to 12th May 2024, it is being organized at Ernakulam under the banner of Chinmaya-Shankaram-2024 with elaborate celebrations. Your active presence and cooperation in all these programs is kindly requested. 
                                </p> */}
                            <div className="mb-4 rounded py-3  text-18">Download and Register now 👇🏼</div>
                                <div className="mt-5 flex flex-wrap">
                                    <Button
                                        className="mr-6 px-6 mb-4 rounded py-3"
                                        variant="contained"
                                        color="primary"
                                        href={"https://play.google.com/store/apps/details?id=com.sparkitchinmayasankaram.f12"}
                                        target={"_blank"}

                                    >
                                        <Icon fontSize="small" className="mr-3">
                                            android
                                        </Icon>
                                        Play Store
                                    </Button>
                                    <Button
                                        className="px-6 mb-4 rounded py-3"
                                        variant="contained"
                                        color="primary"
                                        href={"https://apps.apple.com/gb/app/chinmaya-sankaram/id6468992666?uo=2"} target={"_blank"}
                                    >
                                        <AppleIcon fontSize="small" className="mr-3"/>
                                        App Store
                                    </Button>
                                </div>
                        </div>
                        
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/cs/chinmaya_logo.png"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
            
        </section>
    );
};

export default Intro4;
