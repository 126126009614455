import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Icon } from "@material-ui/core";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  para_style: {
    textAlign: "justify",
  },
  fontHeading: {
    marginTop: "-40px",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      textAlign: "center",
      fontSize: "28px",
    },
  },
  musicImage: {
    width: "362px",
    borderRadius: "10px",
    marginTop: "-50px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: "0px",
      marginLeft: "0px",
    },
  },
  orangeHeading: {
    color: "#F46A00",
    fontSize: "24px", // Adjust the size as needed
  },
}));

const AboutMain = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <section className="section section-intro mt-2" id="intro4">
      <div className="container">
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <div>
              <p className="text-primary m-0 mb-4 text-20">
                <h1 className={classes.fontHeading} style={{ position: "absolute" }}>
                  Chinmaya Mission
                </h1>
              </p>
              <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                Founded in 1953 by Chinmayanandaji disciples under his guidance, Chinmaya Mission is a spiritual, educational, and charitable non-profit organization worldwide that encompasses more than 300 centers in Bharat and abroad. Gurudev set its mission statement with these words-<b> “To provide to individuals, from any background, the wisdom of Vedanta and practical means for spiritual growth and happiness, enabling them to become positive contributors to society.”</b>
              </p>
              <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                From 1951 onward, he spearheaded a global Hindu
                spiritual and cultural renaissance that popularized the
                religion’s esoteric scriptural texts, teaching them in English
                all across India and abroad.
              </p>

              <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                Author of 95 publications in his lifetime, he attended several American and Asian universities as a visiting professor of Indian philosophy and conducted university lecture tours in many countries. He attended Mahasamadhi on 3rd August 1993, but through his Vedantic teachings, publications, centers, ashrams, temples, and social service projects around the globe, he continues to guide the cultural and spiritual evolution of millions of spiritual seekers.
              </p>
            </div>
            {/* Merge the event details here */}
            <div>
              <h2 className={classes.orangeHeading}>Chinmaya Sankaram – 2024 Celebrations 🕉️</h2>
              <ul>
                <li>Address and discourses by eminent speakers and Acharyas from within and outside the Mission</li>
                <li>Acharya Sangaman</li>
                <li>Daily Pooja/Havan</li>
                <li>Exhibition, Book Stall and special food courts and excellent takeaways</li>
                <li>Cultural programs by renowned Artists</li>
                <li>Chanting the entire Bhagavad Gita by more than 30,000 people</li>
              </ul>
              <p>Pujya Swami Swaroopanandaji, Head, Chinmaya Mission Worldwide will be there for this Chinmaya Sankaram – 2024 Celebrations. This presents with a once-in-a-lifetime opportunity to witness and celebrate Gurudev’s 108th jayanti at his birthplace.</p>
              <h2 className={classes.orangeHeading}>Briefing about the program and stay 🏨</h2>
              <ul>
                <li>Since the program is for five days and we expect participation in large numbers, the accommodation will be arranged at different places (Hotel list will be provided) on request at your cost</li>
                <li>Delegates are advised not to register in OYO/Treebo hotels
The Registration fee for the program is Rs.3500/- (excluding cost of accommodation) and Early Bird Registration Fee is Rs. 3000/- till 31 Jan 2024</li>
                <li>The program will be open to all and it's free, but to get the Camp kit and food coupons, you need to register.</li>
                <li>For those who do not register, there will be food counters from which they can buy food.</li>
                <li>Registration is on a first-come-first-serve basis for seating arrangements in the Pandal.</li>
                <li>Travel to Ernakulam and back may be arranged by you.</li>
                <li>Sightseeing assistance can be provided.</li>
                <li>For registration and fee payment download the app now</li>
                <li>For hotel booking, please contact the hotels as provided in the list.</li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default AboutMain;
