import React from 'react'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(({palette, ...theme}) => ({
    banner_img: {
        marginTop: "100px",
        width: "100%",
        boxShadow: "10px",
        marginBottom: "-80px"
    },


    pc_logo: {
      display: "none"
    },
    
    swamiContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "50px",
      marginRight: "50px"
    },

    swami: {
      width: "19%",
      marginTop: "-14%",
      height: "300px",
      borderRadius: "10px",
      

    },

    swami1: {
      animation: "$slideAndBounce 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97)",
      "&:hover": {
        transform: "translateX(0) scale(1.1)",
      },
    },
    
    "@keyframes slideAndBounce": {
      "0%": {
        transform: "translateX(-100%)",
      },
      "60%": {
        transform: "translateX(0)",
      },
      "75%": {
        transform: "translateX(0)",
      },
      "90%": {
        transform: "translateX(0)",
      },
      "100%": {
        transform: "translateX(0)",
      },
    },
    
    swami2: {
      animation: "$slide2AndBounce 1.5s cubic-bezier(0.36, 0.07, 0.19, 0.97)",
      "&:hover": {
        transform: "translateX(0) scale(1.1)",
      },
    },
    
    "@keyframes slide2AndBounce": {
      "0%": {
        transform: "translateX(100%)", 
      },
      "60%": {
        transform: "translateX(0)",
      },
      "75%": {
        transform: "translateX(0)", 
      },
      "90%": {
        transform: "translateX(0)",
      },
      "100%": {
        transform: "translateX(0)",
      },
    },
    
    [theme.breakpoints.down("xs")]: {
        pc_logo: {
          display: "block",
          width: "50px",
          marginTop: "15px",
          marginLeft: "20px",
        },
        banner_img: {
          marginTop: "55px",
          marginBottom: "0px",
          height: "150px"
      },

      swami: {
        width: "22%",
        marginTop: "0%",
        height: "100px"
        
      },

      swamiContainer: {
        marginLeft: "10px",
        marginRight: "10px"
      },
      container_div: {
        marginBottom: "-60px"
      }
  },


  "@media screen and (max-width: 950px) and (min-width: 501px)" :{
    swami: {
      width: "18%",
      marginTop: "-8%",
      height: "176px"
      
    },
    banner_img: { 
      height: "277px",
      objectFit: "cover"
  },

  }

}));


function Banner() {

    const classes = useStyles()


  return (
    <div className={classes.container_div} >
        <a href={"/"}> <img src="/assets/images/cs/chinmaya_logo.png" alt="" className={classes.pc_logo} /></a>
          <img src="/assets/images/cs/banner.jpeg" alt="img" className={`${classes.banner_img}  `} />

        
    </div>
  )
}

export default Banner