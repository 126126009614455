import React from "react";
import {Grid, useMediaQuery} from "@material-ui/core";

const TermsOfUseWeb = () => {

    const isMobile = useMediaQuery("(max-width: 768px");

    return (
        <section className="section">
            <div className="container">
                <Grid container spacing={isMobile ? 3 : 10} alignItems="top">
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <h1 className="mt-13 font-normal text-22">Terms and Conditions - Chinmaya</h1>
                        <p>
                            These Chinmaya Terms and Conditions of Use (these “Terms and Conditions“) set forth the
                            terms and conditions for the use of any and all products and services (collectively, the
                            “Services”) provided by Chinmaya to users of the Services (the “User“ or
                            “Users“, depending upon the context).

                        </p>

                        <div>
                            <p className="text-primary m-0 text-20">1. Definitions</p>
                            <div className="flex items-center mb-4 ">
                                <p>
                                    The following words and terms shall have the meanings set forth below when they are
                                    used in these Terms and Conditions.
                                    <p className="ml-10">
                                        1.1. “Contents” means information such as text, sounds, music, images, videos,
                                        software, programs, computer code, and other information.
                                    </p>
                                    <p className="ml-10">
                                        1.2. “Subject Contents” means Contents that may be accessed through the
                                        Services.
                                    </p>
                                    <p className="ml-10">
                                        1.3. “Submitted Contents” means Contents that Users have submitted, transmitted
                                        or
                                        uploaded on or to the Services.
                                    </p>
                                    <p className="ml-10">
                                        1.4. “Payment” refers to the prepaid payment instrument or the like which Users
                                        may
                                        exchange for Contents and services offered by Chinmaya which are provided for
                                        a
                                        fee within the Services.</p>
                                    <p className="ml-10">
                                        1.5. “Separate Terms and Conditions” means terms and conditions separate from
                                        these
                                        Terms and Conditions that pertain to the Services released or uploaded by
                                        Chinmaya under names such as “terms,” “guidelines,” “policies,” or the like.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">2. Agreement to these Terms and Conditions</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        2.1. All Users shall use the Services in accordance with these Terms and
                                        Conditions.
                                        Users may not use the Services unless they agree to these Terms and Conditions.
                                    </p>
                                    <p className="ml-10">
                                        2.2. Users who are minors may only use the Services by obtaining prior consent
                                        from
                                        their parents or legal guardians. Furthermore, if Users will be using the
                                        Services
                                        on behalf of, or for the purposes of, a business enterprise, then such business
                                        enterprise must also agree to these Terms and Conditions prior to using the
                                        Services.
                                    </p>
                                    <p className="ml-10">
                                        2.3. If there are Separate Terms and Conditions applicable to the Services,
                                        Users
                                        shall also comply with such Separate Terms and Conditions as well as these Terms
                                        and
                                        Conditions in using the Services.
                                    </p>
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="text-primary m-0 text-20">3. Modification to these Terms and Conditions</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2 ml-10">
                                    Chinmaya may modify these Terms and Conditions when Chinmaya deems it to be
                                    necessary, within the scope of the purposes of the Services. In such case,
                                    Chinmaya will indicate the contents of the modified version of these Terms and
                                    Conditions, as well as the effective date of the modification, on the Services or on
                                    Chinmaya website, or will publicize the same to Users by notifying Users in the
                                    manner prescribed by Chinmaya.
                                    The modified version of these Terms and Conditions shall become effective as of the
                                    effective date thereof.
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">4. Account</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        4.1. When using the Services, Users may need to set up an account by registering
                                        certain information. Users must register true, accurate and complete
                                        information,
                                        and must revise the same to keep such information up-to-date at all times.
                                    </p>
                                    <p className="ml-10">
                                        4.2. If Users register any authentication information when using the Services,
                                        they
                                        must exercise due care in handling such information at their own responsibility
                                        to
                                        ensure that such information is not used in an unlawful manner. Chinmaya may
                                        treat any and all activities conducted under the authentication information as
                                        activities that have been conducted by the User with whom the authentication
                                        information is registered.
                                    </p>
                                    <p className="ml-10">
                                        4.3. Any User who has registered for the Services may delete such User’s account
                                        and
                                        cancel the Services at any time.
                                    </p>
                                    <p className="ml-10">
                                        4.4. Chinmaya reserves the right to delete any account that has been inactive
                                        for
                                        a period of one (1) year or more since its last activation, without any prior
                                        notice
                                        to the applicable User.
                                    </p>
                                    <p className="ml-10">
                                        4.5. Any and all rights of a User to use the Service shall cease to exist when
                                        such
                                        User’s account has been deleted for any reason. Please take note that an account
                                        cannot be retrieved even if a User has accidentally deleted their account.
                                    </p>
                                    <p className="ml-10">
                                        4.6. Each account in the Services is for exclusive use and belongs solely to the
                                        User
                                        of such account. Users may not transfer, lease or otherwise dispose of their
                                        rights
                                        to use the Service to any third party, nor may the same be inherited or
                                        succeeded by
                                        any third party.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">5. Privacy</p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        5.1. Chinmaya places top priority on the privacy of its Users.
                                    </p>
                                    <p className="ml-10">
                                        5.2. Chinmaya will appropriately handle privacy information and personal
                                        information of Users in accordance with the Chinmaya Privacy Policy. (to
                                        update
                                        privacy
                                        policy for Chinmaya)
                                    </p>
                                    <p className="ml-10">
                                        5.3. Chinmaya promises to exercise the utmost care and attention to its
                                        security
                                        measures to ensure the safe management of any and all information collected from
                                        Users.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">6. Objectionable Content
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        Chinmaya maintains a zero-tolerance policy regarding objectionable content.
                                        Objectionable content may not be uploaded or displayed to the extent such
                                        content includes, is in conjunction with, or alongside any, Objectionable
                                        Content. Objectionable content includes, but is not limited to :

                                        <p className="ml-10">
                                            (i) sexually explicit materials;
                                        </p>
                                        <p className="ml-10">
                                            (ii) obscene, defamatory, libelous, slanderous, violent, and/or unlawful
                                            content or profanity;
                                        </p>
                                        <p className="ml-10">
                                            (iii) content that infringes upon the rights of any third party, including
                                            copyright, trademark, privacy, publicity or other personal or proprietary
                                            rights, or that is deceptive or fraudulent;
                                        </p>
                                        <p className="ml-10">
                                            (iv) contents that promote the use or sale of illegal or regulated
                                            substances, tobacco products, ammunition and/or firearms; and
                                        </p>
                                        <p className="ml-10">
                                            (v) gambling, including without limitation, any online casino, sports books,
                                            bongo or poker. Any user can flag content they deem objectionable for
                                            review. Content will be moderated by Chinmaya to ensure the timely
                                            removal of any and all objectionable content.
                                        </p>
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">7. Provision of the Service
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        7.1. Users shall supply PCs, mobile phone devices, smartphones and other
                                        communication devices, operating systems, communication methods and electricity,
                                        etc. necessary for using the Services at their own responsibility and expense.
                                    </p>
                                    <p className="ml-10">
                                        7.2. Chinmaya reserves the right to limit access to all or part of the
                                        Services by
                                        Users depending upon conditions that Chinmaya considers necessary, such as
                                        the
                                        age and identification of User, current registration status, and the like.
                                    </p>
                                    <p className="ml-10">
                                        7.3. Chinmaya reserves the right to modify, at Chinmaya’s discretion, all
                                        or
                                        part of the Services as Chinmaya determines necessary anytime without any
                                        prior
                                        notice to Users.
                                    </p>
                                    <p className="ml-10">
                                        7.4. Chinmaya may cease providing all or part of the Services without any
                                        prior
                                        notice to Users in case of the occurrence of any of the following:
                                        <p className="ml-10">
                                            (1) When conducting maintenance or repair of systems;
                                        </p>
                                        <p className="ml-10">
                                            (2) When the Services cannot be provided due to force majeure such as an
                                            accident
                                            (fire, power outage, etc.), act of God, war, riot, labor dispute;
                                        </p>
                                        <p className="ml-10">
                                            (3) When there is system failure or heavy load on the system;
                                        </p>
                                        <p className="ml-10">
                                            (4) When securing the safety of Users or third parties, or in the case of an
                                            emergency for the public welfare; or
                                        </p>
                                        <p className="ml-10">
                                            (5) When Chinmaya reasonably determines it to be necessary, other than
                                            those set
                                            forth in items (1) through (4).
                                        </p>
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">8. No Emergency Call Functions
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    The Services cannot be depended upon for emergency calls, such as those made to law
                                    enforcement agencies, maritime safety authorities, fire brigade or other emergency
                                    services.
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">9. Advertisements

                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                Chinmaya reserves the right to post advertisements for Chinmaya or a third
                                    party on the Services.

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">10. Third-Party Services
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    The Services may contain services or Contents provided by third parties other than
                                    Chinmaya. Such third parties shall bear any and all responsibility regarding such
                                    services and/or Contents. Furthermore, there may be terms or use or other terms and
                                    conditions provided by such third party which are applicable to such services and
                                    Contents.
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">11. Contents
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        11.1. Chinmaya grants Users a non-transferable, non-sublicensable,
                                        non-exclusive
                                        license to use the Subject Contents provided by Chinmaya, solely for the
                                        purpose
                                        of using the Services.
                                    </p>
                                    <p className="ml-10">
                                        11.2. Users shall abide by the conditions applicable thereto when using the
                                        Subject
                                        Contents which are subject to conditions of use, such as additional fees and
                                        periods
                                        of use and the like. Even if terms such as “Purchase”, “Sales,” and the like
                                        appear
                                        on the screens for the Services, Chinmaya shall remain the owner of all
                                        intellectual property rights as well as all other rights in the Subject Contents
                                        offered to Users by Chinmaya, and such rights shall not be transferred to
                                        Users.
                                        Users will only be granted usage rights as set forth above.
                                    </p>
                                    <p className="ml-10">
                                        11.3. Users shall not use the Subject Contents beyond the scope of the intended
                                        use
                                        of the Subject Contents in the Services (including, without limitation, acts of
                                        copying, transmitting, reproducing, and modifying).
                                    </p>
                                    <p className="ml-10">
                                        11.4. If Users wish to back-up all or part of the Submitted Contents, they will
                                        need
                                        to do so themselves.  Chinmaya will not undertake the obligation of backing up
                                        any
                                        of the Submitted Contents.
                                    </p>
                                    <p className="ml-10">
                                        11.5. The Services may include functions where multiple Users may edit Contents
                                        (post, correct, and delete, etc.). In such case, a User who posted his/her
                                        Submitted
                                        Contents shall grant other Users the right to edit such Submitted Contents.
                                    </p>
                                    <p className="ml-10">
                                        11.6. Users shall retain their rights to their Submitted Contents, and
                                        Chinmaya
                                        shall not acquire any such rights; provided, however, that, among the Submitted
                                        Contents, for those made available to other Users generally (i.e., not just to
                                        the
                                        User's “friends”), the User who posted such Submitted Contents shall be deemed
                                        to
                                        have granted Chinmaya a worldwide, perpetual, royalty-free license (including
                                        the
                                        right to amend (such as abbreviating) such Contents to the extent Chinmaya
                                        deems
                                        necessary and appropriate, and the right to sublicense such usage rights to
                                        other
                                        third parties working together with Chinmaya), to use such Contents for
                                        services
                                        and/or promotional purposes.
                                    </p>
                                    <p className="ml-10">
                                        11.7. Users shall not exercise their rights such as author’s moral rights with
                                        respect to the Submitted Contents in relation to the use by Chinmaya or a
                                        third
                                        party under Articles 10.5 and 10.6 above.
                                    </p>
                                    <p className="ml-10">
                                        11.8. Chinmaya may check and confirm the contents of the Submitted Contents
                                        by
                                        Users to the extent permissible under laws and regulations, when it is necessary
                                        for
                                        Chinmaya to confirm compliance with related laws and regulations or the
                                        provisions set out in these Terms and Conditions, etc.; provided, however, that
                                        Chinmaya is not obligated to conduct such confirmation.
                                    </p>
                                    <p className="ml-10">
                                        11.9. If Chinmaya believes that a User has violated or may violate any
                                        applicable
                                        laws or regulations or the provisions of these Terms and Conditions in relation
                                        to
                                        the Submitted Contents, or when otherwise reasonably necessary for Chinmaya’s
                                        business, then Chinmaya reserves the right to preclude such User's use of the
                                        Services in certain manners, such as deleting the Submitted Contents without
                                        providing prior notice to the User.
                                    </p>

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">12. Coins
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        12.1. Coins will be provided to Users by the means specified by Chinmaya,
                                        such as
                                        purchases in the Services, special offers and the like. The purchase unit,
                                        method of
                                        payment, and any other terms and conditions for granting the Coins will be
                                        determined by Chinmaya and indicated in the Services or on Chinmaya’s
                                        website.
                                    </p>
                                    <p className="ml-10">
                                        12.2. Coins may not be exchanged or used for cash, property or other economic
                                        gain
                                        other than the services and Contents designated by Chinmaya. The number of
                                        Coins
                                        required for receiving the services or Contents, as well as other terms for the
                                        exchange of Coins, will be determined by Chinmaya and indicated in the
                                        Services or
                                        on
                                        Chinmaya’s website.
                                    </p>
                                    <p className="ml-10">
                                        12.3. Coins may only be used by the account that obtained the same. Furthermore,
                                        Coins provided by terminal devices of different operating systems may not be
                                        carried
                                        over or combined, even if they are under the same name, since the types of Coins
                                        are
                                        different.
                                    </p>
                                    <p className="ml-10">
                                        12.4. Coins will not be refunded for any reason whatsoever; provided, however,
                                        that
                                        this shall not apply if necessary under applicable laws and regulations. In such
                                        case, Chinmaya shall determine the process for refunds in accordance with the
                                        relevant
                                        laws and regulations, and such information will be made available on
                                        Chinmaya's
                                        website,
                                        etc.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">13. Provision of Subscription Services

                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        13.1. Users may receive services in exchange for payment of a fee or under the
                                        terms and conditions prescribed by Chinmaya, wherein the Users pay a certain
                                        amount of considerations to use certain Subject Contents designated by
                                        Chinmaya for a certain period of time (the “Subscription Services”). Users
                                        shall abide by these Terms and Conditions, the fees for the Subscription
                                        Services, the payment method thereof, as well as other terms and conditions of
                                        use posted on the Services or on Chinmaya’s website.
                                    </p>
                                    <p className="ml-10">
                                        13.2. Users may take procedures for the cancellation of Subscription Services at
                                        any time; provided, however, that even if a User takes the procedures for
                                        cancellation prior to the intended period of use, the User may not change such
                                        period of use, nor may the User cancel the purchase of the Subscription
                                        Services. In such case, the fees already paid will not be refunded, nor will
                                        there be any refund on a pro rata basis. However, the foregoing will not apply
                                        if laws or regulations require otherwise.

                                    </p>
                                    <p className="ml-10">
                                        13.3. If a User does not complete their cancellation procedure by the designated
                                        date and time, the period of use of the Subscription Services may be
                                        automatically renewed in accordance with the terms prescribed by Chinmaya
                                        even after the end of the period of use of such Subscription Services.
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">14. Restricted Matters

                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    Chinmaya prohibits Users from engaging in any of the following acts when using
                                    the Services:

                                    <p className="ml-10">
                                        14.1. Acts that violate the laws and regulations, court verdicts, resolutions or
                                        orders, or administrative measures that are legally binding;
                                    </p>
                                    <p className="ml-10">
                                        14.2. Acts that may be in violation of public order, morals or customs;
                                    </p>
                                    <p className="ml-10">
                                        14.3. Acts that infringe intellectual property rights, such as copyrights,
                                        trademarks and patent rights, rights to fame, privacy, and all other rights
                                        granted by law or by a contract with Chinmaya and/or a third party;
                                    </p>
                                    <p className="ml-10">
                                        14.4. Acts of posting or transmitting excessively violent or explicit sexual
                                        expressions; expressions that amount to child pornography or child abuse;
                                        expressions that lead to discrimination by race, national origin, creed, gender,
                                        social status, family origin, etc.; expressions that induce or encourage
                                        suicide, self-injurious behavior or drug abuse; or expressions that include
                                        anti-social content and lead to the discomfort of others;
                                    </p>
                                    <p className="ml-10">
                                        14.5. Acts that lead to the misrepresentation of Chinmaya and/or a third
                                        party
                                        or that intentionally spread false information;
                                    </p>
                                    <p className="ml-10">
                                        14.6. Acts of sending the same or similar messages to a large, indefinite number
                                        of Users (except for those approved by Chinmaya), indiscriminately adding
                                        other Users as friends or to group chats, or any other acts deemed by
                                        Chinmaya to constitute spamming;
                                    </p>
                                    <p className="ml-10">
                                        14.7. Acts of exchanging the right to use the Services or Contents into cash,
                                        property or other economic benefits, other than by using the method prescribed
                                        by Chinmaya;
                                    </p>
                                    <p className="ml-10">
                                        14.8. Acts of using the Services for sales, marketing, advertising, solicitation
                                        or other commercial purposes (except for those approved by Chinmaya); using
                                        the Services for the purpose of sexual conduct or obscene acts; using the
                                        Services for the purpose of meeting or engaging in sexual encounters with an
                                        unknown third party; using the Services for the purpose of harassment or
                                        libelous attacks against other Users; or otherwise using the Services for
                                        purposes other than as intended by the Services;
                                    </p>
                                    <p className="ml-10">
                                        14.9. Acts that benefit or involve collaboration with anti-social groups;
                                    </p>
                                    <p className="ml-10">
                                        14.10. Acts that are related to religious activities or invitations to certain
                                        religious groups;
                                    </p>
                                    <p className="ml-10">
                                        14.11. Acts of unauthorized or improper collection, disclosure, or provision of
                                        any other person's personal information, registered information, user history,
                                        or the like;
                                    </p>
                                    <p className="ml-10">
                                        14.12. Acts of interfering with the servers and/or network systems of the
                                        Services; fraudulently manipulating the Services by means of bots, cheat tools,
                                        or other technical measures; deliberately using defects of the Services; making
                                        unreasonable inquires and/or undue claims such as repeatedly asking the same
                                        questions beyond what is necessary, and other acts of interfering with or
                                        hindering Chinmaya's operation of the Services or other Users’ use of the
                                        Services;
                                    </p>
                                    <p className="ml-10">
                                        14.13. Acts of decoding the source code of the Services, such as by way of
                                        reverse engineering, disassembling or the like, for unreasonable purposes or in
                                        an unfair manner;
                                    </p>
                                    <p className="ml-10">
                                        14.14. Acts that aid or encourage any acts stated in Clauses 13.1 to 13.13
                                        above;
                                        and
                                    </p>
                                    <p className="ml-10">
                                        14.15. Acts other than those set forth in Clauses 13.1 to 13.14 that Chinmaya
                                        reasonably deems to be inappropriate.
                                    </p>

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">15. User Responsibility
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        15.1. Users shall use the Services at their own risk, and shall bear any and all
                                        responsibilities for actions carried out under and the results from the use of
                                        the Services.
                                    </p>
                                    <p className="ml-10">
                                        15.2. Chinmaya may suspend the use of all or part of the Services, suspend or
                                        delete accounts, cancel any agreement between a User and Chinmaya with
                                        respect to the Services (including, without limitation, any agreement based on
                                        these Terms and Conditions, hereinafter the same) or take any other measure
                                        Chinmaya reasonably determines to be necessary and appropriate without prior
                                        notice to such User in the case that such User falls under or there is a
                                        possibility that such User falls under any of the following items:
                                        <p className="ml-10">
                                            (1) A User is in breach of applicable laws and regulations, these Terms and
                                            Conditions, or any Separate Terms and Conditions;
                                        </p>
                                        <p className="ml-10">
                                            (2) A User is a member of an anti-social group or a related party
                                            thereof;</p>
                                        <p className="ml-10">
                                            (3) A User tarnishes Chinmaya’s credibility by spreading false
                                            information, using fraudulent means or force, or other unlawful means;</p>
                                        <p className="ml-10">
                                            (4) A User is subject to a petition for attachment, provisional attachment,
                                            or auction, ; procedures such as bankruptcy, civil rehabilitation, or
                                            similar procedures are commenced; or Chinmaya otherwise reasonably
                                            determines that there is uncertainty with respect to User’s credibility;
                                            or</p>
                                        <p className="ml-10">
                                            (5) The relationship of trust with a User is lost or Chinmaya otherwise
                                            reasonably determines that it would not be appropriate for Chinmaya to
                                            provide the Services to User, due to reasons other than as set forth in
                                            items (1) to (4) above.
                                        </p>
                                    </p>
                                    <p className="ml-10">
                                        15.3. In the case where Chinmaya suffers loss/damages either directly or
                                        indirectly (including, without limitation, attorney fees) due to a User’s use of
                                        the Services (including, without limitation, cases where Chinmaya receives a
                                        complaint from a third party due to such use), the User shall immediately
                                        compensate Chinmaya for such loss/damage in accordance with Chinmaya’s
                                        request.
                                    </p>

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">16. NO WARRANTY
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        CHINMAYA SHALL PROVIDE NO WARRANTY, EITHER EXPRESSLY OR IMPLIEDLY, WITH
                                        RESPECT TO THE SERVICES (INCLUDING THE SUBJECT CONTENTS), THAT THERE ARE NO
                                        DEFECTS (INCLUDING, WITHOUT LIMITATION, FAULTS WITH RESPECT TO SECURITY, ETC.,
                                        ERRORS OR BUGS, OR VIOLATIONS OF RIGHTS) OR AS TO THE SAFETY, RELIABILITY,
                                        ACCURACY, COMPLETENESS, EFFECTIVENESS AND FITNESS FOR A PARTICULAR PURPOSE.
                                        CHINMAYA SHALL WILL IN NO WAY BE RESPONSIBLE FOR PROVIDING USERS WITH THE SERVICES
                                        AFTER DELETING SUCH DEFECTS. </p>

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">17.  CHINMAYA SHALL's LIMITATION OF LIABILITY
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        17.1.  CHINMAYA SHALL WILL NOT BE LIABLE FOR ANY DAMAGE INCURRED BY USERS FROM THE
                                        USE OF THE SERVICES, UNLESS SUCH DAMAGE IS ATTRIBUTABLE TO THE INTENTIONAL OR
                                        GROSSLY NEGLIGENT ACTS OF  CHINMAYA SHALL; PROVIDED, HOWEVER, THAT, IN THE CASE THAT
                                        THE AGREEMENT BETWEEN THE USER AND  CHINMAYA SHALL WITH RESPECT TO THE USE OF THE
                                        SERVICES FALLS UNDER A CONSUMER CONTRACT AS DEFINED UNDER THE CONSUMER CONTRACT
                                        ACT OF INDIA (“CONSUMER CONTRACT”),  CHINMAYA SHALL WILL BE LIABLE TO PROVIDE
                                        COMPENSATION FOR DAMAGE ARISING DUE TO NEGLIGENCE ON THE PART OF  CHINMAYA SHALL
                                        (EXCLUDING GROSS NEGLIGENCE) ARISING FROM CONTRACT OR TORT ONLY WITHIN THE RANGE
                                        OF
                                        <p className="ml-10"> (A) THE DAMAGE WHICH IS NORMALLY INCURRED (I.E., EXCLUSIVE
                                            OF LOST PROFITS
                                            AND OTHER DAMAGES ARISING UNDER SPECIAL CIRCUMSTANCES), AND </p>
                                        <p className="ml-10">
                                            (B) THE AMOUNT OF USAGE FEES FOR THE SERVICES RECEIVED FROM SUCH USER FOR
                                            FEE-BASED SERVICES FOR
                                            THE MONTH IN WHICH SUCH DAMAGE HAS BEEN INCURRED, AT MAXIMUM.
                                        </p>
                                    </p>
                                    <p className="ml-10">
                                        17.2. IF A USER INCURS DAMAGE AS A RESULT OF GROSS NEGLIGENCE ON THE PART OF
                                        CHINMAYA SHALL,  CHINMAYA SHALL WILL BE RESPONSIBLE TO PROVIDE COMPENSATION FOR THE
                                        DAMAGE INCURRED BUT ONLY WITHIN THE RANGE OF
                                        <p className="ml-10"> (A) THE DAMAGE WHICH IS NORMALLY
                                            INCURRED (I.E., EXCLUSIVE OF LOST PROFITS AND OTHER DAMAGES ARISING UNDER
                                            SPECIAL CIRCUMSTANCES), AND </p>
                                        <p className="ml-10">
                                            (B) THE AMOUNT OF USAGE FEES FOR THE SERVICES
                                            RECEIVED FROM SUCH USER FOR FEE-BASED SERVICES FOR THE MONTH IN WHICH SUCH
                                            DAMAGE HAS BEEN INCURRED, AT MAXIMUM; PROVIDED, HOWEVER, THAT THE FOREGOING
                                            SHALL NOT APPLY IF THE AGREEMENT BETWEEN SUCH USER AND  CHINMAYA SHALL FALLS
                                            UNDER
                                            THE DEFINITION OF A CONSUMER CONTRACT.
                                        </p>
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">18. Relationship between these Terms and Conditions
                                and Laws and Regulations
                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        If the terms of these Terms and Conditions violate any laws and regulations
                                        applicable to an agreement between Users and  CHINMAYA SHALL with respect to the
                                        Services (including, without limitation, the Consumer Contract Act of India),
                                        such terms, to the extent of such violation, shall not apply to the agreement
                                        with the Users; provided, however, that the remaining terms of these Terms and
                                        Conditions shall not be affected.
                                    </p>

                                </p>
                            </div>
                        </div>

                        <div>
                            <p className="text-primary m-0 text-20">19. Notification and Contact

                            </p>
                            <div className="flex items-center mb-4">
                                <p className="my-2">
                                    <p className="ml-10">
                                        19.1. For notifications from  CHINMAYA SHALL to Users regarding the Services,
                                        CHINMAYA SHALL will use a method that  CHINMAYA SHALL considers appropriate, such as
                                        posting in an appropriate place within the Services or on  CHINMAYA SHALL’s website.
                                    </p>
                                    <p className="ml-10">
                                        19.2. For notifications from Users to  CHINMAYA SHALL regarding the Services, Users
                                        shall use the customer inquiry form available on the Services or on
                                        CHINMAYA SHALL’s website, or through other means designated by  CHINMAYA SHALL.

                                    </p>

                                </p>
                            </div>
                        </div>
                    </Grid>

                </Grid>
            </div>
        </section>
    );
};

export default TermsOfUseWeb;
