import React, {useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button, Icon} from "@material-ui/core";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const useStyles = makeStyles((theme) => ({

    para_style: {
        textAlign: "justify",
    },

    fontHeading: {
        marginTop: "-60px",
        fontSize: "30px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "30px !important",
            marginLeft: "0px !important",
            textAlign: "center",
            fontSize: "17px",
        },
        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
                marginLeft: "60px",
          }
    },

    themeText: {
        color: theme.palette.text.primary,
    },

    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-95px"
        },
    },


    musicImage: {
        width: "362px",
        marginTop: "-50px",
        marginLeft: "100px",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginTop: "0px",
            marginLeft: "0px"
        },

        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
            width: "287px",
            marginLeft: "52px",
        }
    
    },
    video: {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    modal: {
        zIndex: "999",
        padding: "10px"
    },
    musicImageWrapper: {
        position: "relative",
        display: "inline-block",
        "&::before": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: "80%",
            left: 0,
            right: 0,
            margin: "0 250px 40px -2000px",
            bottom: 200,
            borderRadius: "300px",
            background: theme.palette.primary.main,
            transformOrigin: "right bottom",
            transform: "rotate(135deg)",
            zIndex: -1,
            opacity: 0.1,
        },

        "&::after": {
            content: '" "',
            position: "absolute",
            display: "block",
            height: 36,
            width: "80%",
            marginTop: 32,
            left: "20%",
            opacity: 0.75,
            background:
                "radial-gradient(ellipse at center,  rgba(0,0,0,0.65) -25%,rgba(0,0,0,0) 55%);",
        },

        [theme.breakpoints.down("xs")]: {
            display: "block",
            "&::after": {
                display: "none",
            },
            "&::before": {
                display: "none",
            },
        },

       
      
        
    },

    img: {
        width: "400px",
        height: "300px",
        objectFit: "cover",
        cursor: "pointer",
        borderRadius: "10px",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.4)",
        [theme.breakpoints.down("xs")]: {
          width: "170px",
          height: "200px",
      },
    },
}));

const Intro4 = () => {
    const classes = useStyles();
   
    const swiperRef = useRef(null);

    useEffect(() => {
      if (swiperRef.current) {
          new window.Swiper(swiperRef.current, {
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            pagination: {
              clickable: true,
            },
            effect: "coverflow",
            coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: false,
            },
            slidesPerView: 2  ,
            centeredSlides: true,
            style: { height: "700px" },
            autoplay: {
              delay: 1000,
              disableOnInteraction: false,
            },
            
          });
        }
    }, []);
  
  
    const IMG = [
      {img: "/assets/images/inaguration/1.jpeg"},
      {img: "/assets/images/inaguration/2.jpeg"},
      {img: "/assets/images/inaguration/3.jpeg"},
      {img: "/assets/images/inaguration/4.jpeg"},
     
  
  
  
    ]
  
  
  
  

    return (
        <section className={`${classes.container_top} section section-intro`} id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    
                    <Grid item lg={12} md={6} sm={6} xs={12}>
                        <div>
                            <p  className="text-primary m-0 mb-4 text-10">
                                <h5 className={classes.fontHeading} >Swami Chinmayananda’s 108th Jayanti celebrations have begun</h5>
                            </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `} >
                                    Kochi- Chinmaya Mission founder Swami Chinmayananda's 108th Jayanti celebrations got off to a grand start. Shri Arif Mohammad Khan, Hon’ble Governor of Kerala inaugurated the program named Chinmaya Shankaram 2024 in a grand function at Ernakulam TDM Hall on 31st October, 2023. The Hon’ble Governor recalled that Swami Chinmayananda was a great person who worked tirelessly for the transformation of the society. The Governor specially recalled the marvellous role played by Gurudev in spreading Vedanta in the world.
                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                    The Governor also specially appreciated the service activities carried out by Chinmaya Mission all over the world following the footsteps of Swamiji. The governor also reminded that culture does not mean looking at the comfort of one person and causing inconvenience to others. He said that every Malayali should be proud of the great tradition of Kerala, because of the birth of great personalities like Swami Chinmayananda and Adi Sankara.
                                </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                The year-long Jayanti celebrations have been organized under the joint auspices of Chinmaya Mission Kerala Region and Chinmaya Seva Trust Kerala. Chinmaya Mission Kerala Regional Head Swami Viviktananda Saraswati introduced the audience to what Chinmaya Shankaram 2024 is all about. Chinmaya Seva Trust Kerala Chief Sevak R. Suresh Mohan explained the tradition and activities of Chinmaya Mission to the audience.
                            </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                C.M.E.C.T. Chief Sevak A. Gopalakrishnan welcomed the event. C.M.E.C.T. Trustee KS Vijayakumar handed over the gift of Chinmaya Mission to the Hon’ble Governor. Senior Swamis of Chinmaya Mission, Prof. Ajay Kapur, Vice Chancellor of Chinmaya Vishwa Vidyapeeth, Deemed -to-be   University   and people from various walks of life came to witness the inauguration of Chinmaya Shankaram 2024.
                            </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                As part of Chinmaya Shankaram 2024, a wide range of celebratory programs have been organized under the leadership of Chinmaya Mission. Various  programs like Geeta Yajna, spiritual discourses, various competitions for students and Geeta study classes are planned in different parts of the state.
                            </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Chinmaya Mission Kerala Division has decided to celebrate the upcoming birthdays of these two unique spiritual giants, Swami Chinmayananda and Adi Sankara, born in Ernakulam district with due solemnity. Thus, from 08th  to 12th May 2024, a five day program is being organized at Ernakulathappan Temple Grounds, Ernakulam, Kerala under the banner of Chinmaya-Shankaram-2024 with elaborate celebrations. To be held in the august presence of Pujya Guruji Swami Tejomayananda and Swami Swaroopananda, the mega event will have a variety of spiritual menus like discourses, lectures, Cultural Programmes, Gayathri Havan, talks by Acharyas and other learned scholars, Yathi Puja of 108 Sannyasins, Gita Parayanam, Soundarya Lahari Parayanam, Nagarasankeerthanam, Ratha Yatra, special celebrations at Sri Sankara's birth place in Adi Sankara Nilayam, Veliyanadu and Guru Paduka Puja.
                            </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Registration for the event is being started through Chinmaya Sankaram Mobile App which can be downloaded from our website <a href={"www.chinmayasankaram.org"} target="+_blank">www.chinmayasankaram.org</a>
                            </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Jai Jai Chinmaya, Jai Jai Sankara!
                                </p>
                            <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                PIC- Chinmaya Shankaram 2024 Brochure on Commencement of 108th  Jayanti Celebration of Chinmaya Mission Founder Swami Chinmayananda released by Governor Arif Muhammad Khan
                            </p>
                        </div>
                        
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                          
                            <div >
                                    <div className={`swiper-container mt-10  ${classes.galleryContainer} `} ref={swiperRef}>
                                        <div className="swiper-wrapper">
                                        {/* Sample slides */}
                                        {IMG?.map((image, index) => (
                                            <div className="swiper-slide" key={index}>
                                            <img className={classes.img} src={image.img} alt={`Slide ${index + 1}`} />
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                    
                            </div>
                    </Grid>
                </Grid>               
            </div>
            
        </section>
    );
};

export default Intro4;
