import React from "react";
import TopBar from "./sections/cs/TopBar";
import PrivacyPolicy from "./sections/cs/PrivacyPolicyWeb";
import Footer2 from "./sections/cs/Footer2";
import {isMobile} from "react-device-detect";
import {useMediaQuery} from "@material-ui/core";
import TopBarMobile from "./sections/cs/TopBarMobile";
import Banner from "./sections/Banner";
import Banner1 from "./sections/Banner1";

const PolicyWeb = () => {

    const mobile = useMediaQuery("(max-width: 768px");
    return (
        <div className="landing">
            <TopBar/>
            <Banner1/>
            <PrivacyPolicy/>
            <Footer2/>
        </div>
    );
};

export default PolicyWeb;
