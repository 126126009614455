import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button, Icon} from "@material-ui/core";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const useStyles = makeStyles((theme) => ({

    para_style: {
        textAlign: "justify",
    },

    fontHeading: {
        marginTop: "-60px",
        fontSize: "40px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-35px",
            textAlign: "center",
            fontSize: "28px",
        },
        "@media screen and (max-width: 778px) and (min-width: 501px)" :{
            fontSize: "28px",
        }
    },

    musicImage: {
        width: "362px",
        borderRadius: "10px",
        marginTop: "-50px",
        
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginTop: "-150px",
            marginLeft: "0px"
        },

        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
            width: "250px",
        }
    },

    paraContainer: {
        "@media screen and (max-width: 778px) and (min-width: 501px)" :{
            marginLeft: "30px",
            maxWidth: "61.667%",

        }
    }

}));

const About = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <section className="section section-intro" id="intro4">
            <div className="container">
                <Grid container spacing={3} alignItems="center">
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/cs/swami2.jpeg"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12} className={classes.paraContainer} >
                        <div>
                            <p className="text-primary m-0 mb-4 text-20">
                                <h1 className={classes.fontHeading} style={{position: "absolute"}}>Swami Chinmayananda</h1>
                            </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `} >
                                Born as Balakrishna Menon on 8th May, 1916, Swami 
                                Chinmayananda is counted amongst the most notable 
                                spiritual leaders in India. Considered an authority on 
                                the ancient Indian scriptures, especially the Bhagavad 
                                Gita and the Upanishads, he inspired the <b> formation of 
                                Chinmaya Mission to spread the knowledge of Advaita 
                                Vedanta </b>.
                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                From 1951 onward, he spearheaded a global Hindu 
                                spiritual and cultural renaissance that popularized the 
                                religion’s esoteric scriptural texts, teaching them in English 
                                all across India and abroad.
                                </p>

                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Author of 95 publications in his lifetime, he attended several American and Asian universities as a visiting professor of Indian philosophy and conducted university lecture tours in many countries. He attended Mahasamadhi on 3rd August, 1993; but through his Vedantic teachings, publications, centers, ashrams, temples and social service projects around the globe, he continues to guide the cultural and spiritual evolution of millions of spiritual seekers.
                                </p>
                               
                           </div> 
                               
                    </Grid>

                   
                    
                </Grid>

               
            </div>
        </section>
    );
};

export default About;
