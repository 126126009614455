import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Button, Icon} from "@material-ui/core";


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const useStyles = makeStyles((theme) => ({

    para_style: {
        textAlign: "justify",
    },

    fontHeading: {
        marginTop: "-60px",
        fontSize: "40px",
        [theme.breakpoints.down("xs")]: {
            marginTop: "-50px",
            textAlign: "center",
            fontSize: "28px",
        },
    },

    container_top: {
        [theme.breakpoints.down("xs")]: {
            marginTop: "-100px",
        },
    },

    readMore: {
        color: "blue",
        textDecoration: "underline",
        cursor: "pointer"
    },
  

   

    musicImage: {
        width: "362px",
        borderRadius: "10px",
        marginTop: "-50px",
        
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginLeft: "0px"
        },

        "@media screen and (max-width: 950px) and (min-width: 501px)" :{
            width: "250px",
        }
    },

    paraContainer: {
        "@media screen and (max-width: 778px) and (min-width: 501px)" :{
            marginLeft: "30px",
            

        }
    }

 

}));

const Mission = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <section className={`${classes.container_top} section section-intro`} id="intro4">
            <div className="container">

                <Grid container spacing={3} alignItems="center">
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                        <div className={classes.musicImageWrapper}>
                            <img
                                className={classes.musicImage}
                                src="/assets/images/cs/another_banner.jpg"
                                alt="mobile-1"
                            />
                        </div>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                        <div className={classes.paraContainer}>
                            <p className="text-primary m-0 mb-4 mt-8 text-20">
                                <h1 className={classes.fontHeading} style={{position: "absolute"}}>Chinmaya Mission</h1>
                            </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `} >
                                Founded in 1953 by Chinmayanandaji disciples under his guidance, Chinmaya Mission is a spiritual, educational and charitable non-profit organization worldwide that encompasses more than 300 centers in Bharat and abroad. Gurudev set its mission statement with these words-<b> “To provide to individuals, from any background, the wisdom of Vedanta and practical means for spiritual growth and happiness, enabling them to become positive contributors to society.”</b>
                                </p>
                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                From 1951 onward, he spearheaded a global Hindu 
                                spiritual and cultural renaissance that popularized the 
                                religion’s esoteric scriptural texts, teaching them in English 
                                all across India and abroad.
                                </p>

                                <p className={`max-w-500 mb-8 ${classes.para_style} `}>
                                Author of 95 publications in his lifetime, he attended several American and Asian universities as a visiting professor of Indian philosophy and conducted university lecture tours in many countries. He attended Mahasamadhi on 3rd August, 1993; but through his Vedantic teachings, publications, centers, ashrams, temples and social service projects around the globe, he continues to guide the cultural and spiritual evolution of millions of spiritual seekers. <a href="/about"  className={classes.readMore} >Read More.....</a>
                                </p>
                               
                           </div> 
                    </Grid>
                </Grid>               
            </div>
        </section>
    );
};

export default Mission;
