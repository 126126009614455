import React, { useContext, useState } from "react";
import { makeStyles, IconButton } from "@material-ui/core";
import modalContext from "../../modal-context";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 999,
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
    zIndex: 998,
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    padding: theme.spacing(2),
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    borderRadius: "8px",
    zIndex: 999,
    width: "100%",
    maxWidth: "80%",
    maxHeight: "90%",
    overflow: "auto",
  },
  closeIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  content: {
    textAlign: "center",
    // Your other content styles
  },
  fontHeading: {
    marginTop: "-40px",
    fontSize: "32px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-50px",
      textAlign: "center",
      fontSize: "28px",
    },
  },
  pdf: {
      width: "80%",
      marginTop: "10px",
      [theme.breakpoints.down("xs")]: {
          height: "550px",
          width: "100%",
        },
  }
}));

const TenativeProgram = () => {
  const classes = useStyles();
  const { handleCloseModal } = useContext(modalContext);

  const handleModalClose = () => {
    handleCloseModal(); 
  };

  return (
    <section className="section" id="ten">
 <div className={classes.modalContainer}>
    <div className={classes.overlay} onClick={handleModalClose}></div>
        <div className={classes.modal}>
          <IconButton onClick={handleModalClose} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
          <div className={classes.content}>
          <section className="section">
            
            <p className="text-primary m-0 mb-2 mt-10 text-20">
                <h1 className={classes.fontHeading}>
                Tentative Program Details
                </h1>
              </p>
                <img className={classes.pdf} src="/assets/images/PROGRAM DETAILS LATEST_page-0001.jpg" alt="img" />
           
        </section>
          </div>
        </div>

        </div>
    </section>
  );
};

export default TenativeProgram;
