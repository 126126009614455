import React, {useEffect} from "react";
import {scrollTo} from "../utils";
import TopBar4 from "./sections/cs/TopBar4";
import Intro4 from "./sections/cs/Intro4";
import Features4 from "./sections/cs/Features4";
import Features2 from "./sections/cs/Features2";
import Footer2 from "./sections/cs/Footer2";
import Banner from "./sections/Banner";
import About from "./sections/About";
import Mission from "./sections/Mission";
import {isMobile} from "react-device-detect";
import AboutMain from "./sections/AboutMain";
import TopAbout from "./sections/cs/TopAbout";
import { useMediaQuery } from "@material-ui/core";
import Banner1 from "./sections/Banner1";
import GalleryMain from "./sections/GalleryMain";
import VideoMainGallery from "./sections/VideoMainGallery";



const IntroVideoGallery = () => {
    useEffect(() => {
        scrollTo("root");
    });

    const mobile = useMediaQuery("(max-width: 768px");

    return (
        <div className="landing">
            <TopAbout/>
            <Banner1/>
            <VideoMainGallery/>
            <Footer2/>
                {/* <OrgList  /> */}
        </div>
    );
};

export default IntroVideoGallery;
